// Package modules
import { useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

// Local modules
import { api } from './api';
import { AuthService } from './authService';
import { BLOG_TYPES, PRODUCT_TYPES } from './constants';

// Constants
const STATE_TYPES = {
  PRODUCT: 'product',
  PRODUCTS: 'products',
  PARTNERS: 'partners',
  BLOG_POST: 'blog_post',
  BLOG_POSTS: 'blog_posts',
  ALL_PRODUCTS: 'all_products',
  THEME_SETTINGS: 'theme_settings',
  WAREHOUSE_LIST: 'get-warehouse-list',
  MAILLETTERS: 'mailletters',
  CONTACTS: 'contacts',
};

const STALE_TIME = 60000;

// Exports
// Auth service state
/** Get details about the current logged-in user */
export function useAuthService() {
  const authService = AuthService.getAuthServiceInstance();
  const authenticatedUser = authService.getAuthenticatedUser();

  // Memoize static props independent of authenticated user changes.
  const staticProps = useMemo(() => ({
    authService,
    login: authService.login.bind(authService),
    logout: authService.logout.bind(authService),
    setAuthenticatedUser: authService.setAuthenticatedUser.bind(authService),
  }), [authService]);

  return {
    authenticatedUser,
    ...staticProps,
  };
}

export const useProductList = (categoryId) => {
  const queryClient = useQueryClient();
  // console.log("👉 queryClient", queryClient, "👈")

  const getProducts = () => api.products.getProducts(categoryId);
  const { data } = useQuery([STATE_TYPES.PRODUCTS, categoryId], getProducts, {
    onError: err => console.log("👉", err, "👈"),
    staleTime: STALE_TIME,
    enabled: !!categoryId,
    notifyOnChangeProps: ['data', 'error']
  });

  const createProduct = ({ productType, product }) => api.products.createProduct(productType, product);
  const { mutateAsync: mutateCreateProduct } = useMutation(createProduct, {
    onSuccess: (newProduct, oldProduct) => {
      console.warn("👉 newProduct", newProduct, oldProduct, "👈")
      const cachedProducts = queryClient.getQueryData([STATE_TYPES.PRODUCTS, categoryId]);
      queryClient.setQueryData([STATE_TYPES.PRODUCTS, categoryId], [
        ...cachedProducts,
        newProduct
      ]);
    },
    onError: err => console.log("👉", err, "👈")
  });

  return {
    data,
    createProduct: mutateCreateProduct
  };
};

export const useAllProduct = () => {
  const getProducts = () => Promise.allSettled([
    api.products.getProducts(PRODUCT_TYPES.MAKEUP),
    api.products.getProducts(PRODUCT_TYPES.SKINCARE),
    api.products.getProducts(PRODUCT_TYPES.FRAGRANCE),
    api.products.getProducts(PRODUCT_TYPES.CANDLES)
  ]);
  const { data: [makeup, skincare, fragrance, candle] } = useQuery([STATE_TYPES.ALL_PRODUCTS], getProducts, {
    onError: err => console.log("👉", err, "👈"),
    staleTime: STALE_TIME,
    notifyOnChangeProps: ['data', 'error']
  });

  return { data: [...makeup.value, ...skincare.value, ...fragrance.value, ...candle.value] };
};

export const useProduct = (productType, productId) => {
  const queryClient = useQueryClient();

  const getProduct = () => api.products.getProduct(productType, productId);
  const { data } = useQuery([STATE_TYPES.PRODUCT, productId], getProduct, {
    onError: err => console.log("👉", err, "👈"),
    staleTime: STALE_TIME,
    enabled: !!productId,
    notifyOnChangeProps: ['data', 'error']
  });

  const deleteProduct = () => api.products.deleteProduct(productType, productId);
  const { mutateAsync: mutateDeleteProduct } = useMutation(deleteProduct, {
    onSuccess: (response, entity) => {
      console.log("👉 response, entity", response, entity, queryClient.getQueryData([STATE_TYPES.PRODUCTS, productType]), "👈")
      const cachedList = [...queryClient.getQueryData([STATE_TYPES.PRODUCTS, productType]) || []];
      const entityIndex = cachedList.findIndex(entityInList => entityInList.id === productId);

      // Only remove if the entity is in the list.
      if (entityIndex !== -1) {
        cachedList.splice(entityIndex, 1);
        queryClient.setQueryData([STATE_TYPES.PRODUCTS, productType], cachedList);
      }
    },
    onError: (err) => console.log("👉", err, "👈")
  });

  const updateProduct = (product) => api.products.updateProduct(productType, productId, product);
  const { mutateAsync: mutateUpdateProduct } = useMutation(updateProduct, {
    onSuccess: (response, product) => {
      console.log("👉 response, entity", response, product, queryClient.getQueryData([STATE_TYPES.PRODUCTS, productType]), "👈")
      const cachedList = [...queryClient.getQueryData([STATE_TYPES.PRODUCTS, productType]) || []];
      const entityIndex = cachedList.findIndex(entityInList => entityInList.id === productId);

      // Only remove if the entity is in the list.
      if (entityIndex !== -1) {
        cachedList[entityIndex] = response;
        queryClient.setQueryData([STATE_TYPES.PRODUCTS, productType], cachedList);
        queryClient.setQueryData([STATE_TYPES.PRODUCT, productId], response);
      }
    },
    onError: (err) => console.log("👉", err, "👈")
  });

  return {
    data,
    deleteProduct: mutateDeleteProduct,
    updateProduct: mutateUpdateProduct
  };
};

export const useBlogPosts = (blogType) => {
  const queryClient = useQueryClient();

  const getPosts = () => api.blog.getPosts(blogType);
  const { data } = useQuery([STATE_TYPES.BLOG_POSTS, blogType], getPosts, {
    onError: err => console.log("👉 getPosts > onError", err, "👈"),
    staleTime: STALE_TIME,
    enabled: !!blogType,
    notifyOnChangeProps: ['data', 'error']
  });

  const createPost = (product) => api.blog.createPost(product);
  const { mutateAsync: mutateCreatePost } = useMutation(createPost, {
    onSuccess: (newPost) => {
      console.warn("👉 newPost", newPost, "👈")
      const postBlogType = newPost.is_news ? BLOG_TYPES.NEWS : BLOG_TYPES.ARTICLES;
      const cachedPosts = queryClient.getQueryData([STATE_TYPES.BLOG_POSTS, postBlogType]);
      queryClient.setQueryData([STATE_TYPES.BLOG_POSTS, postBlogType], [
        ...cachedPosts,
        newPost
      ]);
    },
    onError: err => console.log("👉", err, "👈")
  });

  return {
    data,
    createPost: mutateCreatePost
  }
};

export const usePost = (blogType, postId) => {
  const queryClient = useQueryClient();

  const getPost = () => api.blog.getPost(postId);
  const { data } = useQuery([STATE_TYPES.BLOG_POST, postId], getPost, {
    onError: err => console.log("👉", err, "👈"),
    staleTime: STALE_TIME,
    enabled: !!postId,
    notifyOnChangeProps: ['data', 'error']
  });

  const deletePost = () => api.blog.deletePost(postId);
  const { mutateAsync: mutateDeletePost } = useMutation(deletePost, {
    onSuccess: () => {
      const cachedList = [...queryClient.getQueryData([STATE_TYPES.BLOG_POSTS, blogType]) || []];
      const entityIndex = cachedList.findIndex(entityInList => entityInList.id === postId);

      // Only remove if the entity is in the list.
      if (entityIndex !== -1) {
        cachedList.splice(entityIndex, 1);
        queryClient.setQueryData([STATE_TYPES.BLOG_POSTS, blogType], cachedList);
      }
    },
    onError: (err) => console.log("👉", err, "👈")
  });

  const updatePost = (postProps) => api.blog.updatePost(postId, postProps);
  const { mutateAsync: mutateUpdatePost } = useMutation(updatePost, {
    onSuccess: (response) => {
      const cachedList = [...queryClient.getQueryData([STATE_TYPES.BLOG_POSTS, blogType]) || []];
      const entityIndex = cachedList.findIndex(entityInList => entityInList.id === postId);

      // Only remove if the entity is in the list.
      if (entityIndex !== -1) {
        cachedList[entityIndex] = response;
        queryClient.setQueryData([STATE_TYPES.BLOG_POSTS, blogType], cachedList);
        queryClient.setQueryData([STATE_TYPES.BLOG_POST, postId], response);
      }
    },
    onError: (err) => console.log("👉", err, "👈")
  });

  return {
    data,
    deletePost: mutateDeletePost,
    updatePost: mutateUpdatePost
  };
};

export const useThemeSettings = () => {
  const queryClient = useQueryClient();

  const getThemeSettings = () => api.theme.getThemeSettings();
  const { data } = useQuery(STATE_TYPES.THEME_SETTINGS, getThemeSettings, {
    onError: err => console.log("👉", err, "👈"),
    staleTime: STALE_TIME,
    notifyOnChangeProps: ['data', 'error']
  });

  const updateThemeSettings = (themeConfig) => api.theme.updateThemeSettings(themeConfig);
  const { mutateAsync: mutateUpdateThemeSettings } = useMutation(updateThemeSettings, {
    onSuccess: (response) => {
      const cachedEntity = queryClient.getQueryData(STATE_TYPES.THEME_SETTINGS) ?? {};

      // Only remove if the entity is in the list.
      queryClient.setQueryData(STATE_TYPES.THEME_SETTINGS, {
        ...cachedEntity,
        ...response
      });
    },
    onError: (err) => console.log("👉", err, "👈")
  });

  return {
    data,
    updateThemeSettings: mutateUpdateThemeSettings
  }
};

export const usePartners = () => {
  const getList = () => api.partner.getList();
  const { data } = useQuery(STATE_TYPES.PARTNERS, getList, {
    onError: err => console.log("👉", err, "👈"),
    staleTime: STALE_TIME,
    notifyOnChangeProps: ['data', 'error']
  });

  return { data }
};

export const useMessages = () => {
  const getList = () => api.admin.getList();
  const { data } = useQuery(STATE_TYPES.MAILLETTERS, getList, {
    onError: err => console.log("👉", err, "👈"),
    staleTime: STALE_TIME,
    notifyOnChangeProps: ['data', 'error']
  });

  return { data }
};


export const useContacts = () => {
  const getListContacts = () => api.admin.getListContacts();
  const { data } = useQuery([STATE_TYPES.CONTACTS], getListContacts, {
    onError: err => console.log("👉 getPosts > onError", err, "👈"),
    staleTime: STALE_TIME,
    notifyOnChangeProps: ['data', 'error']
  });
  return { data}
}

  export const  useContact = (contactId) => {
  const getListContactById = () => api.admin.getListContactById(contactId);
  const { data } = useQuery([STATE_TYPES.CONTACTS, contactId], getListContactById, {
    onError: err => console.log("👉", err, "👈"),
    staleTime: STALE_TIME,
    enabled: !!contactId,
    notifyOnChangeProps: ['data', 'error']
  });
  return { data }
};
// Package modules
import React, { useState, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import {
  View,
  Router,
  NotFoundBoundary
} from 'react-navi';
import {
  QueryClient,
  QueryClientProvider
} from 'react-query';
import { HelmetProvider } from 'react-helmet-async';
import 'simplebar/src/simplebar.css';

// Local modules
import * as serviceWorker from './serviceWorker';
import { appRoutes } from './routes';
import { AuthService } from './lib/authService';
import NotFound from './pages/Page404';
import ThemeConfig from './theme';
import ScrollToTop from './components/ScrollToTop';
import GlobalStyles from './theme/globalStyles';
import reportWebVitals from './reportWebVitals';
import { ProgressCircular } from './components/Progress';
import { BaseOptionChartStyle } from './components/charts/BaseOptionChart';
import {
  TOKEN_EXPIRED,
  ENTITY_NOT_FOUND
} from './components/error/ApiError';


// QueryClient instance
const reactQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry(failureCount, error) {
        // Do not retry requests that 404.
        const { sourceError } = error;
        if (sourceError?.error === ENTITY_NOT_FOUND) {
          return false;
        }
        // Do not retry on token expiration errors.
        if (sourceError?.error === TOKEN_EXPIRED) {
          return false;
        }
        return 3 - failureCount;
      },
      suspense: true
    }
  }
});

// Exports
const App = () => (
  <ThemeConfig>
    <QueryClientProvider client={ reactQueryClient }>
      <Suspense fallback={ <ProgressCircular /> }>
        <ScrollToTop />
        <GlobalStyles />
        <BaseOptionChartStyle />
        <NotFoundBoundary render={ () => <NotFound /> }>
          <View />
        </NotFoundBoundary>
      </Suspense>
    </QueryClientProvider>
  </ThemeConfig>
);

const Index = () => {
  const [authenticatedUser, setAuthenticatedUser] = useState(null);
  AuthService.getAuthServiceInstance().setQueryClient(reactQueryClient);
  AuthService.getAuthServiceInstance().subscribe(setAuthenticatedUser);

  return (
    <HelmetProvider>
      <Router
        routes={ appRoutes }
        context={{ authenticatedUser }}
      >
        <App />
      </Router>
    </HelmetProvider>
  )
};

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript


root.render(
  <Index />
);


// ReactDOM.render(
//   <Index />,
//   document.getElementById('root')
// );

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

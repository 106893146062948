// Package modules
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Link as RouterLink } from 'react-navi';
import {
  Link,
  Card,
  Grid,
  CardContent
} from '@mui/material';

// Assets
import DummySrc from '../../../assets/dummy.jpg';

// Styled components
const CardMediaStyle = styled('div')({
  position: 'relative',
  paddingTop: 'calc(100% * 3 / 4)'
});

const TitleStyle = styled(Link)({
  height: 44,
  overflow: 'hidden',
  WebkitLineClamp: 2,
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical'
});

const CoverImgStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute'
});

// Exports
export default function BlogPostCard({ post, blogType }) {
  const { title } = post;

  return (
    <Grid
      item
      xs={ 12 }
      sm={ 6 }
      md={ 3 }
    >
      <Card sx={{ position: 'relative' }}>
        <CardMediaStyle>
          <CoverImgStyle
            alt={ title }
            src={ post?.cover?.length > 0 ? post?.cover : DummySrc }
          />
        </CardMediaStyle>
        <CardContent>
          <TitleStyle
            href={`/dashboard/blog/edit/${post.id}?blogType=${blogType}`}
            color="inherit"
            variant="subtitle2"
            underline="hover"
            component={ RouterLink }
          >
            { title }
          </TitleStyle>
        </CardContent>
      </Card>
    </Grid>
  );
}

BlogPostCard.propTypes = {
  post: PropTypes.object.isRequired,
  blogType: PropTypes.string
};

// Local modules
// import * as path from 'node:path';
import Iconify from '../components/Iconify';

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

export const HOST = 'https://giterbackend.azurewebsites.net/api';

export const PRODUCT_TYPES = {
  MAKEUP: 'makeup',
  SKINCARE: 'skincare',
  FRAGRANCE: 'fragrance',
  CANDLES: 'candles',
};

export const BLOG_TYPES = {
  NEWS: 'news',
  ARTICLES: 'articles'
};

export const MAILLETTERS_TYPE = {
  MAILLETTERS: 'mailletters',
};

export const ROOT_CATEGORIES = {
  [PRODUCT_TYPES.MAKEUP]: 'MakeUp',
  [PRODUCT_TYPES.SKINCARE]: 'Skin Care',
  [PRODUCT_TYPES.FRAGRANCE]: 'Fragrance',
  [PRODUCT_TYPES.CANDLES]: 'Candles',

};

export const ROOT_BLOG_CATEGORIES = {
  [BLOG_TYPES.NEWS]: 'News',
  [BLOG_TYPES.ARTICLES]: 'Articles'
};

export const SIDEBAR_CONFIG = [
  {
    title: 'giter',
    path: '/dashboard/theme',
    icon: getIcon('mdi:pencil-ruler')
  },
  {
    title: 'partner requests',
    path: '/dashboard/partner-requests',
    icon: getIcon('mdi:account-plus')
  },
  {
    title: 'categories',
    // path: `/dashboard/products?productType=${PRODUCT_TYPES.MAKEUP}`,
    icon: getIcon('eva:shopping-bag-fill'),
    children: [
      {
        title: ROOT_CATEGORIES[PRODUCT_TYPES.MAKEUP],
        path: `/dashboard/products?productType=${PRODUCT_TYPES.MAKEUP}`,
        icon: getIcon('mdi:account-plus')
      },
      {
        title: ROOT_CATEGORIES[PRODUCT_TYPES.FRAGRANCE],
        path: `/dashboard/products?productType=${PRODUCT_TYPES.FRAGRANCE}`,
        icon: getIcon('mdi:account-plus')
      },
      {
        title: ROOT_CATEGORIES[PRODUCT_TYPES.SKINCARE],
        path: `/dashboard/products?productType=${PRODUCT_TYPES.SKINCARE}`,
        icon: getIcon('mdi:account-plus')
      },
      {
        title: ROOT_CATEGORIES[PRODUCT_TYPES.CANDLES],
        path: `/dashboard/products?productType=${PRODUCT_TYPES.CANDLES}`,
        icon: getIcon('mdi:account-plus')
      }
    ]
  },
  {
    title: 'blog',
    // path: '/dashboard/blog',
    icon: getIcon('mdi:post-outline'),
    children: [
      {
        title: ROOT_BLOG_CATEGORIES[BLOG_TYPES.NEWS],
        path: `/dashboard/blog?blogType=${BLOG_TYPES.NEWS}`,
        icon: getIcon('mdi:account-plus')
      },
      {
        title: ROOT_BLOG_CATEGORIES[BLOG_TYPES.ARTICLES],
        path: `/dashboard/blog?blogType=${BLOG_TYPES.ARTICLES}`,
        icon: getIcon('mdi:account-plus')
      }
    ]
  },
  {
    title: 'mailletters',
    path: '/dashboard/mailletters',
    icon: getIcon('mdi:mail-outline'),
  },
  {
    title: 'contacts',
    path: '/dashboard/contacts',
    icon: getIcon('basil:contacts-outline'),
  }
];

export const PRODUCT_ENUM = {
  "sex": [
    { "value": "unisex", "label": "Unisex" },
    { "value": "man", "label": "Man" },
    { "value": "women", "label": "Women" }
  ],
  "makeup_types": [
    { "value": "eyeliner", "label": "Eyeliner" },
    { "value": "mascara", "label": "Mascara" },
    { "value": "lipstick", "label": "Lipstick" },
    { "value": "concealer", "label": "Concealer" },
    { "value": "lip_gloss", "label": "Lip Gloss" },
    { "value": "face_powder", "label": "Face Powder" },
    { "value": "loose_powder" , "label": "Loose Powder"},
    { "value": "lip_gloss_tattoo" , "label": "Lip Gloss Tattoo"},
  ],
  "part_of_the_face": [
    { "value": "face", "label": "Face" },
    { "value": "eyes", "label": "Eyes" },
    { "value": "lips", "label": "Lips" }
  ],
  "skin_care_place": [
    { "value": "face", "label": "Face" },
    { "value": "body", "label": "Body" }
  ],
  "skin_care_sub_type": [
    { "value": "care", "label": "Care" },
    { "value": "need", "label": "Need" }
  ],
  "skin_care_type": [
    { "value": "Антивозрастной уход", "label": "Антивозрастной уход" },
    { "value": "Очищение", "label": "Очищение" },
    { "value": "Крем против морщин", "label": "Крем против морщин" },
    { "value": "Лифтинг", "label": "Лифтинг" },
    { "value": "Омоложение", "label": "Омоложение" },
    { "value": "Увлажнение и питание", "label": "Увлажнение и питание" },
    { "value": "Восстановление", "label": "Восстановление" },
    { "value": "Пигментация", "label": "Пигментация" }
  ],
  "skin_care_care": [
    { "value": "Гель-мусс", "label": "Гель-мусс" },
    { "value": "Шелковистое молочко", "label": "Шелковистое молочко" },
    { "value": "Оживляющий тоник", "label": "Оживляющий тоник" },
    { "value": "Антивозрастной крем для век", "label": "Антивозрастной крем для век" },
    { "value": "Укрепляющий-компресс лифтинг для", "label": "Укрепляющий-компресс лифтинг для" },
    { "value": "Дневной крем", "label": "Дневной крем" },
    { "value": "Ночной крем", "label": "Ночной крем" },
    { "value": "Энзимный пилинг", "label": "Энзимный пилинг" },
    { "value": "Маска увлажняющая с эффектом ботокса", "label": "Маска увлажняющая с эффектом ботокса" },
    { "value": "Гиалуроновая сыворотка", "label": "Гиалуроновая сыворотка" }
  ]
};

export const MAKEUP_COLORS = {
  "lipstick_colors": [
    {
      "name": "О1 Пралине",
      "color": "#eca698"
    },
    {
      "name": "O2 Коралл",
      "color": "#f18281"
    },
    {
      "name": "О3 Роза Грасса",
      "color": "#ee8bae"
    },
    {
      "name": "O4 Божоле",
      "color": "#d16373"
    },
    {
      "name": "O5 Маджента",
      "color": "#ef5d9e"
    },
    {
      "name": "O6 Пурпур",
      "color": "#cc2b78"
    },
    {
      "name": "O7 Шокко",
      "color": "#bf765d"
    },
    {
      "name": "O8 Кармен",
      "color": "#ee4251"
    },
    {
      "name": "O9 Аврора",
      "color": "#f06556"
    },
    {
      "name": "O10 Итальянская Роза",
      "color": "#d66b89"
    },
    {
      "name": "O11 Розовый Кедр",
      "color": "#bc726f"
    },
    {
      "name": "O12 Марсала",
      "color": "#ba1f3d"
    },
    {
      "name": "O13 Ваниль",
      "color": "#d87b70"
    },
    {
      "name": "O14 Аметист",
      "color": "#d57689"
    },
    {
      "name": "O15 Бордо",
      "color": "#681b13"
    },
    {
      "name": "O16 Дымчатый Кедр",
      "color": "#c67a7b"
    }
  ],
  "lip_gloss_colors": [
    {
      "name": "G01",
      "color": "#ad6259"
    },
    {
      "name": "G02",
      "color": "#c9665e"
    },
    {
      "name": "G03",
      "color": "#e97c74"
    },
    {
      "name": "G04",
      "color": "#c1473c"
    },
    {
      "name": "G05",
      "color": "#e27278"
    },
    {
      "name": "G06",
      "color": "#914645"
    },
    {
      "name": "G07",
      "color": "#8c2647"
    },
    {
      "name": "G08",
      "color": "#d44133"
    },
    {
      "name": "G09",
      "color": "#c1202e"
    },
    {
      "name": "G10",
      "color": "#a11d21"
    }
  ],
  "face_powder_colors": [
    {
      "name": "MP701",
      "color": "#ead5c7"
    },
    {
      "name": "MP702",
      "color": "#e4b694"
    },
    {
      "name": "MP703",
      "color": "#d1a282"
    },
    {
      "name": "MP704",
      "color": "#ce9e8a"
    }
  ],
  "concealer_colors": [
    {
      "name": "F01",
      "color": "#fbdbc8"
    },
    {
      "name": "F02",
      "color": "#efc9ac"
    },
    {
      "name": "F03",
      "color": "#eec1a2"
    },
    {
      "name": "F04",
      "color": "#e4b694"
    }
  ],
  "loose_powder_colors": [
    {
      "name": "1",
      "color": "#fffaf6"
    },
    {
      "name": "2",
      "color": "#ecd4c7"
    }
  ],
  "lip_gloss_tattoo_colors": [
    {
      "name": "Paradise",
      "color": "#9a4752"
    },
    {
      "name": "Smoky Desert",
      "color": "#87313b"
    },
    {
      "name": "Sexy Coral",
      "color": "#8c3e3e"
    },
    {
      "name": "Red Queen",
      "color": "#b8202f"
    },
    {
      "name": "Beige",
      "color": "#c17b77"
    },
    {
      "name": "Rosy Flash",
      "color": "#c37f79"
    },
    {
      "name": "Naomi",
      "color": "#764349"
    },
    {
      "name": "Barbaris",
      "color": "#c55956"
    },
    {
      "name": "Nude",
      "color": "#dd6e50"
    }
  ]
};

export const FRAGRANCES = [
  {
    "sex": "women",
    "name": "ЦВЕТОЧНО-ФРУКТОВЫЕ",
    "fragrances": [
      "Сладкие",
      "Фруктовые",
      "Свежие",
      "Шипровые"
    ]
  },
  {
    "sex": "women",
    "name": "ЦВЕТОЧНЫЕ",
    "fragrances": [
      "Свежие",
      "Цитрусовые",
      "Водяные",
      "Альдегидные",
      "Интригующие",
      "Восточные",
      "Восточные Сладкие",
      "Шипровые",
      "Древесные",
      "Древесно-мускусные"
    ]
  },
  {
    "sex": "women",
    "name": "ВОДЯНЫЕ",
    "fragrances": [
      "Свежие"
    ]
  },
  {
    "sex": "women",
    "name": "ДРЕВЕСНО-МУСКУСНЫЕ",
    "fragrances": [
      "Цитрусовые",
      "Свежие"
    ]
  },
  {
    "sex": "women",
    "name": "ВОСТОЧНЫЕ ГУРМАНСКИЕ",
    "fragrances": [
      "Гурманские"
    ]
  },
  {
    "sex": "man",
    "name": "ФУЖЕРНЫЕ",
    "fragrances": [
      "Зеленые",
      "Восточные",
      "Цитрусовые"
    ]
  },
  {
    "sex": "man",
    "name": "ЦВЕТОЧНЫЕ",
    "fragrances": [
      "Древесные",
      "Свежие Океанические",
      "Древесно-мускусные"
    ]
  },
  {
    "sex": "man",
    "name": "ДРЕВЕСНЫЕ",
    "fragrances": [
      "Фужерные",
      "Пряные",
      "Древесные",
      "Мускусные",
      "Цветочно-мускусные"
    ]
  },
  {
    "sex": "man",
    "name": "СВЕЖИЕ",
    "fragrances": [
      "Цветочно-океанические",
      "Спортивные"
    ]
  },
  {
    "sex": "man",
    "name": "КОЖАНЫЕ",
    "fragrances": []
  },
];

// Package modules
import React, {useEffect, useRef, useState} from 'react';
import ReactQuill, {Quill} from "react-quill";
import htmlEditButton from "quill-html-edit-button";
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import {LoadingButton, TabContext, TabList, TabPanel} from '@mui/lab';
import {useNavigation} from 'react-navi';
import 'react-quill/dist/quill.snow.css';
import {Form, FormikProvider, useFormik} from 'formik';
import {
  Box,
  Container,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  styled,
  Tab,
  TextField,
  Typography
} from '@mui/material';

// Local modules
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import FileLoader from '../../components/FileLoader';
import {useDebounce} from '../../lib/utils';
import {useProduct, useProductList} from '../../lib/state';
import {Select, SelectOption} from '../../components/Select';
import {FRAGRANCES, MAKEUP_COLORS, PRODUCT_ENUM, PRODUCT_TYPES, ROOT_CATEGORIES} from '../../lib/constants';

// Quill.register("EDITOR_MODULES/htmlEditButton", htmlEditButton);

// Constants
const MAX_FILES = 10;

const EDITOR_MODULES = {
  toolbar: [
    [{ 'header': [1, 2, false] }],
    ['bold', 'italic', 'underline','strike', 'blockquote'],
    [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
    ['link', 'image']
  ],
  htmlEditButton: {},
};

const EDITOR_FORMATS = [
  'header',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image'
];

const FRAGRANCE_FIELDS = {
  COLLECTION_NAME: 'collection_name',
  BRAND_NAME: 'brand_name',
  NAME_FROM_BRAND: 'name_from_brand',
  SEX: 'sex',
  PARFUM_FORM: 'parfum_form',
  PARFUM_FORM_RO: 'parfum_form.ro',
  PARFUM_FORM_RU: 'parfum_form.ru',
  PARFUM_FORM_EN: 'parfum_form.en',
  FRAGRANCE_TYPE: 'fragrance_type',
  FRAGRANCE_NUMBER: 'fragrance_number',
  FRAGRANCE_TOP_NOTES: 'fragrance_top_notes',
  FRAGRANCE_TOP_NOTES_RO: 'fragrance_top_notes.ro',
  FRAGRANCE_TOP_NOTES_RU: 'fragrance_top_notes.ru',
  FRAGRANCE_TOP_NOTES_EN: 'fragrance_top_notes.en',
  FRAGRANCE_HEART_NOTES: 'fragrance_heart_notes',
  FRAGRANCE_HEART_NOTES_RO: 'fragrance_heart_notes.ro',
  FRAGRANCE_HEART_NOTES_RU: 'fragrance_heart_notes.ru',
  FRAGRANCE_HEART_NOTES_EN: 'fragrance_heart_notes.en',
  FRAGRANCE_BASE_NOTES: 'fragrance_base_notes',
  FRAGRANCE_BASE_NOTES_RU: 'fragrance_base_notes.ru',
  FRAGRANCE_BASE_NOTES_RO: 'fragrance_base_notes.ro',
  FRAGRANCE_BASE_NOTES_EN: 'fragrance_base_notes.en',
  ONE_C_CODE: 'one_c_code'
};

const MAKEUP_FIELDS = {
  TYPE: 'type',
  PART: 'part',
  BRAND_NAME: 'brand_name',
  NAME_FROM_BRAND: 'name_from_brand',
  COLOR: 'color',
  FRAGRANCE_NUMBER: 'fragrance_number'
};

const SKIN_CARE_FIELDS = {
  PLACE: 'place',
  SUB_TYPE: 'sub_type',
  TYPE: 'type',
  BRAND_NAME: 'brand_name',
  NAME_FROM_BRAND: 'name_from_brand',
  ACTIVE_COMPONENTS: 'active_components',
  ACTIVE_COMPONENTS_RO: 'active_components.ro',
  ACTIVE_COMPONENTS_RU: 'active_components.ru',
  ACTIVE_COMPONENTS_EN: 'active_components.en',
  APPLYING_METHOD: 'applying_method',
  APPLYING_METHOD_RO: 'applying_method.ro',
  APPLYING_METHOD_RU: 'applying_method.ru',
  APPLYING_METHOD_EN: 'applying_method.en',
  COMPOUND: 'compound',
  COMPOUND_RO: 'compound.ro',
  COMPOUND_RU: 'compound.ru',
  COMPOUND_EN: 'compound.en',
  SKIN_TYPE: 'skin_type',
  SKIN_TYPE_RO: 'skin_type.ro',
  SKIN_TYPE_RU: 'skin_type.ru',
  SKIN_TYPE_EN: 'skin_type.en',

};

const CANDLE_FIELDS = {
  COMPOUND: 'compound',
  COMPOUND_RO: 'compound.ro',
  COMPOUND_RU: 'compound.ru',
  COMPOUND_EN: 'compound.en',
  TYPE: 'fragrance_type',
  TYPE_RO: 'fragrance_type.ro',
  TYPE_RU: 'fragrance_type.ru',
  TYPE_EN: 'fragrance_type.en',
};


// Styled components
const FieldsContainer = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  width: 100%;
  
  @media (max-width: 720px) {
    grid-template-columns: 1fr;
  }
`;

const ReactQuillContainer = styled(Stack)`
  & .quill {
    background-color: ${({ theme }) => theme.palette.common.white};
    border-radius: 8px;
  }
  
  & .ql-toolbar.ql-snow {
    border-color: ${({ theme }) => theme.palette.grey['50032']};
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  
  & .ql-container.ql-snow {
    border-color: ${({ theme }) => theme.palette.grey['50032']};
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  
  & .ql-editor {
    font-size: 1rem;
    min-height: 200px;
  }
`;

const StyledForm = styled(Form)`
  background-color: white;
  border: 1px dashed ${({ theme }) => theme.palette.grey['50032']};
  border-radius: 8px;
  padding: 24px;
`;

const transformFragrancesForSelect = (sex, fragrances) => fragrances.map(fragrance => ({
  value: fragrance,
  label: fragrance,
  prepend: (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '0px' }}>
      {
        sex === 'man' && (
          <Iconify
            icon="ic:baseline-man"
            sx={{ width: 24, height: 24 }}
          />
        )
      }
      {
        sex === 'women' && (
          <Iconify
            icon="ic:baseline-woman"
            sx={{ width: 24, height: 24 }}
          />
        )
      }
      {
        sex === 'unisex' && (
          <>
            <Iconify
              icon="ic:baseline-man"
              sx={{ width: 24, height: 24 }}
            />
            <Iconify
              icon="ic:baseline-woman"
              sx={{ width: 24, height: 24 }}
            />
          </>
        )
      }
    </Box>
  )
}));

const transformFragranceTypesForSelect = (sex, fragranceTypes) => fragranceTypes
  // .filter(type => type.sex === sex.value)
  .map(type => ({
    ...type,
    value: `${type.sex}-${type.name}`,
    label: type.name,
    options: transformFragrancesForSelect(type.sex, type.fragrances)
}));


const transformColorsForSelect = (colors) => colors?.map(color => ({
  ...color,
  value: color.name,
  label: color.name,
  prepend: (
    <Box
      sx={{
        width: 30,
        height: 30,
        borderRadius: 50,
        backgroundColor: color.color
      }}
    />
  )
}));


const FragranceFeatureFields = ({
  product,
  setFragranceFeatures,
  setIsProductFormValid,
}) => {
  const [sex, setSex] = useState(product?.sex ? PRODUCT_ENUM.sex.find(sex => sex.value === product.sex) : PRODUCT_ENUM.sex[0]);
  const [fragranceTypes, setFragranceTypes] = useState([]);

  // const [fragranceBaseNotes, setFragranceBaseNotes] = useState( product?.fragrance_base_notes ? { ru: product.fragrance_base_notes.ru, ro: product.fragrance_base_notes.ro, en: product.fragrance_base_notes.en } : null);


  const transformedFragrances = transformFragranceTypesForSelect(sex, FRAGRANCES);

  const handleSexChange = (value) => {
    setSex(value);
    setFragranceTypes(null);
  };

  const ProductSchema = Yup.object().shape({
    [FRAGRANCE_FIELDS.COLLECTION_NAME]: Yup.string().required('This field is required!').required('This field is required!'),
    [FRAGRANCE_FIELDS.BRAND_NAME]: Yup.string().required('This field is required!'),
    [FRAGRANCE_FIELDS.NAME_FROM_BRAND]: Yup.string().required('This field is required!'),
    // [FRAGRANCE_FIELDS.PARFUM_FORM]: Yup.string().required('This field is required!'),
    [FRAGRANCE_FIELDS.FRAGRANCE_NUMBER]: Yup.string().required('This field is required!'),
    // [FRAGRANCE_FIELDS.FRAGRANCE_TOP_NOTES]: Yup.string().required('This field is required!'),
    // [FRAGRANCE_FIELDS.FRAGRANCE_HEART_NOTES]: Yup.string().required('This field is required!'),
    // [FRAGRANCE_FIELDS.FRAGRANCE_BASE_NOTES]: Yup.string().required('This field is required!'),
    [FRAGRANCE_FIELDS.ONE_C_CODE]: Yup.string().required('This field is required!'),
  });

  const productForm = useFormik({
    initialValues: {
      [FRAGRANCE_FIELDS.COLLECTION_NAME]: product?.collection_name ?? '',
      [FRAGRANCE_FIELDS.BRAND_NAME]: product?.brand_name ?? '',
      [FRAGRANCE_FIELDS.NAME_FROM_BRAND]: product?.name_from_brand ?? '',
      [FRAGRANCE_FIELDS.PARFUM_FORM]: product?.parfum_form ?? '',
      [FRAGRANCE_FIELDS.FRAGRANCE_NUMBER]: product?.fragrance_number ?? '',
      [FRAGRANCE_FIELDS.FRAGRANCE_TOP_NOTES]: product?.fragrance_top_notes ?? '',
      [FRAGRANCE_FIELDS.FRAGRANCE_HEART_NOTES]: product?.fragrance_heart_notes ?? '',
      [FRAGRANCE_FIELDS.FRAGRANCE_BASE_NOTES]: product?.fragrance_base_notes ?? '',
      [FRAGRANCE_FIELDS.ONE_C_CODE]: product?.one_c_code ?? '',
    },
    validationSchema: ProductSchema,
  });

  const debouncedProductFormIsValid = useDebounce(productForm.isValid);

  const { getFieldProps } = productForm;

  useEffect(() => {
    // console.log("👉 fragranceTypes", fragranceTypes, "👈")
    const fragranceFeaturesPayload = {
      [FRAGRANCE_FIELDS.SEX]: sex.value,
      [FRAGRANCE_FIELDS.FRAGRANCE_TYPE]: fragranceTypes?.map(f => f.value),
      [FRAGRANCE_FIELDS.COLLECTION_NAME]: productForm.values[FRAGRANCE_FIELDS.COLLECTION_NAME],
      [FRAGRANCE_FIELDS.BRAND_NAME]: productForm.values[FRAGRANCE_FIELDS.BRAND_NAME],
      [FRAGRANCE_FIELDS.NAME_FROM_BRAND]: productForm.values[FRAGRANCE_FIELDS.NAME_FROM_BRAND],
      [FRAGRANCE_FIELDS.PARFUM_FORM]: productForm.values[FRAGRANCE_FIELDS.PARFUM_FORM],
      [FRAGRANCE_FIELDS.FRAGRANCE_NUMBER]: productForm.values[FRAGRANCE_FIELDS.FRAGRANCE_NUMBER],
      [FRAGRANCE_FIELDS.FRAGRANCE_TOP_NOTES]: productForm.values[FRAGRANCE_FIELDS.FRAGRANCE_TOP_NOTES],
      [FRAGRANCE_FIELDS.FRAGRANCE_HEART_NOTES]: productForm.values[FRAGRANCE_FIELDS.FRAGRANCE_HEART_NOTES],
      [FRAGRANCE_FIELDS.FRAGRANCE_BASE_NOTES]: productForm.values[FRAGRANCE_FIELDS.FRAGRANCE_BASE_NOTES],
      [FRAGRANCE_FIELDS.ONE_C_CODE]: productForm.values[FRAGRANCE_FIELDS.ONE_C_CODE],
    };

    setFragranceFeatures(fragranceFeaturesPayload);
  }, [
    sex,
    fragranceTypes,
    setFragranceFeatures,
    productForm.values,
    // productForm.values[FRAGRANCE_FIELDS.COLLECTION_NAME],
    // productForm.values[FRAGRANCE_FIELDS.BRAND_NAME],
    // productForm.values[FRAGRANCE_FIELDS.NAME_FROM_BRAND],
    // productForm.values[FRAGRANCE_FIELDS.PARFUM_FORM],
    // productForm.values[FRAGRANCE_FIELDS.FRAGRANCE_NUMBER],
    // productForm.values[FRAGRANCE_FIELDS.FRAGRANCE_TOP_NOTES],
    // productForm.values[FRAGRANCE_FIELDS.FRAGRANCE_HEART_NOTES],
    // productForm.values[FRAGRANCE_FIELDS.FRAGRANCE_BASE_NOTES],
    // productForm.values[FRAGRANCE_FIELDS.ONE_C_CODE],
  ]);

  useEffect(() => {
    setIsProductFormValid(debouncedProductFormIsValid);
  }, [debouncedProductFormIsValid, setIsProductFormValid]);

  useEffect(() => {
    productForm.validateForm();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  return (
    <FormikProvider value={ productForm }>
      <StyledForm
        autoComplete="off"
        noValidate
      >
        <Stack spacing={ 3 }>
          <Typography variant="h6">
            Fragrance Features
          </Typography>
          <FieldsContainer spacing={ 1 }>
            <Stack spacing={ 1 }>
              <Typography
                variant="subtitle1"
                component="p"
              >
                Collection name
              </Typography>
              <TextField
                fullWidth
                type="text"
                { ...getFieldProps(FRAGRANCE_FIELDS.COLLECTION_NAME) }
              />
            </Stack>
            <Stack spacing={ 1 }>
              <Typography
                variant="subtitle1"
                component="p"
              >
                Brand name
              </Typography>
              <TextField
                fullWidth
                type="text"
                { ...getFieldProps(FRAGRANCE_FIELDS.BRAND_NAME) }
              />
            </Stack>
            <Stack spacing={ 1 }>
              <Typography
                variant="subtitle1"
                component="p"
              >
                Name from brand
              </Typography>
              <TextField
                fullWidth
                type="text"
                { ...getFieldProps(FRAGRANCE_FIELDS.NAME_FROM_BRAND) }
              />
            </Stack>
            {/*<Stack spacing={ 1 }>*/}
            {/*  <Typography*/}
            {/*    variant="subtitle1"*/}
            {/*    component="p"*/}
            {/*  >*/}
            {/*    Perfume form RU*/}
            {/*  </Typography>*/}
            {/*  <TextField*/}
            {/*    fullWidth*/}
            {/*    type="text"*/}
            {/*    { ...getFieldProps(FRAGRANCE_FIELDS.PARFUM_FORM) }*/}
            {/*  />*/}
            {/*</Stack>*/}
            <Stack spacing={ 1 }>
              <Typography
                variant="subtitle1"
                component="p"
              >
                Sex
              </Typography>
              <Select
                defaultValue={ sex }
                value={ sex }
                components={{ Option: SelectOption }}
                options={ PRODUCT_ENUM.sex }
                placeholder="Search..."
                onChange={ handleSexChange }
                closeMenuOnSelect={ false }
                noOptionsMessage={ () => 'No products found' }
              />
            </Stack>
            <Stack spacing={ 1 }>
              <Typography
                variant="subtitle1"
                component="p"
              >
                Fragrance
              </Typography>
              <Select
                defaultValue={ fragranceTypes }
                value={ fragranceTypes }
                components={{ Option: SelectOption }}
                options={ transformedFragrances }
                placeholder="Search..."
                onChange={ setFragranceTypes }
                closeMenuOnSelect={ false }
                noOptionsMessage={ () => 'No products found' }
                isMulti
              />
            </Stack>
            {/*<Stack spacing={ 1 }>*/}
            {/*  <Typography*/}
            {/*    variant="subtitle1"*/}
            {/*    component="p"*/}
            {/*  >*/}
            {/*    Fragrance Type*/}
            {/*  </Typography>*/}
            {/*  <Select*/}
            {/*    defaultValue={ fragranceType }*/}
            {/*    value={ fragranceType }*/}
            {/*    components={{ Option: SelectOption }}*/}
            {/*    options={ fragranceTypes }*/}
            {/*    placeholder="Search..."*/}
            {/*    onChange={ setFragranceType }*/}
            {/*    closeMenuOnSelect={ false }*/}
            {/*    // hideSelectedOptions={ false }*/}
            {/*    // controlShouldRenderValue={ false }*/}
            {/*    noOptionsMessage={ () => 'No products found' }*/}
            {/*    isDisabled={ fragrance === null }*/}
            {/*    isMulti*/}
            {/*  />*/}
            {/*</Stack>*/}
            <Stack spacing={ 1 }>
              <Typography
                variant="subtitle1"
                component="p"
              >
                Fragrance Number
              </Typography>
              <TextField
                fullWidth
                type="text"
                { ...getFieldProps(FRAGRANCE_FIELDS.FRAGRANCE_NUMBER) }
              />
            </Stack>
            {/*<Stack spacing={ 1 }>*/}
            {/*  <Typography*/}
            {/*    variant="subtitle1"*/}
            {/*    component="p"*/}
            {/*  >*/}
            {/*    Fragrance Top Notes*/}
            {/*  </Typography>*/}
            {/*  <TextField*/}
            {/*    fullWidth*/}
            {/*    type="text"*/}
            {/*    { ...getFieldProps(FRAGRANCE_FIELDS.FRAGRANCE_TOP_NOTES) }*/}
            {/*  />*/}
            {/*</Stack>*/}
            {/*<Stack spacing={ 1 }>*/}
            {/*  <Typography*/}
            {/*    variant="subtitle1"*/}
            {/*    component="p"*/}
            {/*  >*/}
            {/*    Fragrance Heart Notes*/}
            {/*  </Typography>*/}
            {/*  <TextField*/}
            {/*    fullWidth*/}
            {/*    type="text"*/}
            {/*    { ...getFieldProps(FRAGRANCE_FIELDS.FRAGRANCE_HEART_NOTES) }*/}
            {/*  />*/}
            {/*</Stack>*/}
            {/*<Stack spacing={ 1 }>*/}
            {/*  <Typography*/}
            {/*    variant="subtitle1"*/}
            {/*    component="p"*/}
            {/*  >*/}
            {/*    Fragrance Base Notes RU*/}
            {/*  </Typography>*/}
            {/*  <TextField*/}
            {/*    fullWidth*/}
            {/*    type="text"*/}
            {/*    { ...getFieldProps(FRAGRANCE_FIELDS.FRAGRANCE_BASE_NOTES) }*/}
            {/*  />*/}
            {/*</Stack>*/}
            <Stack spacing={ 1 }>
              <Typography
                variant="subtitle1"
                component="p"
              >
                1C Code
              </Typography>
              <TextField
                fullWidth
                type="text"
                { ...getFieldProps(FRAGRANCE_FIELDS.ONE_C_CODE) }
              />
            </Stack>
          </FieldsContainer>
        </Stack>
      </StyledForm>
      <StyledForm
          autoComplete="off"
          noValidate
      >
          <Typography variant="h6">
            Fragrance Multi-language fields
          </Typography>

            <TabContext value={value}>
              <Box sx={{ width: "100%"}}>
                <TabList onChange={handleChange}>
                  <Tab label="text_RO" value="1" />
                  <Tab label="text_RU" value="2" />
                  <Tab label="text_EN" value="3" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <Typography variant="subtitle1" component="p">Perfume form RO</Typography>
                <TextField style={{margin: "15px" }} multiline maxRows={10} fullWidth type="text"  { ...getFieldProps(FRAGRANCE_FIELDS.PARFUM_FORM_RO) }/>
                <div style={{fontWeight: "600"}}> Fragrance Top Notes RO</div>
                <TextField style={{margin: "15px" }} multiline maxRows={10} fullWidth type="text"  { ...getFieldProps(FRAGRANCE_FIELDS.FRAGRANCE_TOP_NOTES_RO) } />
                <div style={{fontWeight: "600"}}> Fragrance Heart Notes RO</div>
                <TextField style={{margin: "15px" }} multiline maxRows={10} fullWidth type="text"  { ...getFieldProps(FRAGRANCE_FIELDS.FRAGRANCE_HEART_NOTES_RO) } />
                <div style={{fontWeight: "600"}}>Fragrance Base Notes RO</div>
                <TextField style={{margin: "15px" }} multiline maxRows={10} fullWidth type="text"  { ...getFieldProps(FRAGRANCE_FIELDS.FRAGRANCE_BASE_NOTES_RO) } />
              </TabPanel>
              <TabPanel value="2">
                <div style={{fontWeight: "600"}}>Perfume form RU</div>
                <TextField style={{margin: "15px" }} multiline maxRows={10} fullWidth type="text" { ...getFieldProps(FRAGRANCE_FIELDS.PARFUM_FORM_RU) } />
                <div style={{fontWeight: "600"}}> Fragrance Top Notes RU</div>
                <TextField style={{margin: "15px" }} multiline maxRows={10} fullWidth type="text" { ...getFieldProps(FRAGRANCE_FIELDS.FRAGRANCE_TOP_NOTES_RU) } />
                <div style={{fontWeight: "600"}}> Fragrance Heart Notes RU</div>
                <TextField style={{margin: "15px" }} multiline maxRows={10} fullWidth type="text" { ...getFieldProps(FRAGRANCE_FIELDS.FRAGRANCE_HEART_NOTES_RU) } />
                <div style={{fontWeight: "600"}}>Fragrance Base Notes RU</div>
                <TextField style={{margin: "15px" }} multiline maxRows={10} fullWidth type="text" { ...getFieldProps(FRAGRANCE_FIELDS.FRAGRANCE_BASE_NOTES_RU) } />
              </TabPanel>
              <TabPanel value="3">
                <div style={{fontWeight: "600"}}>Perfume form EN</div>
                <TextField style={{margin: "15px" }} multiline maxRows={10} fullWidth type="text" { ...getFieldProps(FRAGRANCE_FIELDS.PARFUM_FORM_EN) } />
                <div style={{fontWeight: "600"}}> Fragrance Top Notes EN</div>
                <TextField style={{margin: "15px" }} multiline maxRows={10} fullWidth type="text" { ...getFieldProps(FRAGRANCE_FIELDS.FRAGRANCE_TOP_NOTES_EN) } />
                <div style={{fontWeight: "600"}}> Fragrance Heart Notes EN</div>
                <TextField style={{margin: "15px" }} multiline maxRows={10} fullWidth type="text" { ...getFieldProps(FRAGRANCE_FIELDS.FRAGRANCE_HEART_NOTES_EN) } />
                <div style={{fontWeight: "600"}}>Fragrance Base Notes EN</div>
                <TextField style={{margin: "15px" }} multiline maxRows={10} fullWidth type="text"  { ...getFieldProps(FRAGRANCE_FIELDS.FRAGRANCE_BASE_NOTES_EN) }/>
              </TabPanel>
            </TabContext>

      </StyledForm>
    </FormikProvider>
  );
};

const MakeupFeatureFields = ({
  product,
  setMakeupFeatures,
  setIsProductFormValid
}) => {
  const [part, setPart] = useState(product?.part ? PRODUCT_ENUM.part_of_the_face.find(part => part.value === product.part) : PRODUCT_ENUM.part_of_the_face[0]);
  const [type, setType] = useState(product?.type ? PRODUCT_ENUM.makeup_types.find(type => type.value === product.type) : PRODUCT_ENUM.makeup_types[2]);
  const [color, setColor] = useState( product?.color ? { value: product.color.color, label: product.color.name } : null);

  const allColors = MAKEUP_COLORS?.[`${type.value}_colors`];
  const transformedColors = transformColorsForSelect(allColors);

  const ProductSchema = Yup.object().shape({
    [MAKEUP_FIELDS.BRAND_NAME]: Yup.string().required('This field is required!'),
    [MAKEUP_FIELDS.NAME_FROM_BRAND]: Yup.string().required('This field is required!'),
    [MAKEUP_FIELDS.FRAGRANCE_NUMBER]: Yup.string().required('This field is required!')
  });

  const productForm = useFormik({
    initialValues: {
      [MAKEUP_FIELDS.BRAND_NAME]: product?.brand_name ?? '',
      [MAKEUP_FIELDS.NAME_FROM_BRAND]: product?.name_from_brand ?? '',
      [MAKEUP_FIELDS.FRAGRANCE_NUMBER]: product?.fragrance_number ?? ''
    },
    validationSchema: ProductSchema,
  });

  const debouncedProductFormIsValid = useDebounce(productForm.isValid);

  const { getFieldProps } = productForm;

  const onTypeChange = (value) => {
    setType(value);
    setColor([]);
  };

  useEffect(() => {
    const makeupFeaturesPayload = {
      [MAKEUP_FIELDS.TYPE]: type.value,
      [MAKEUP_FIELDS.PART]: part.value,
      [MAKEUP_FIELDS.COLOR]: color ? { name: color.name, color: color.color } : null,
      [MAKEUP_FIELDS.BRAND_NAME]: productForm.values[MAKEUP_FIELDS.BRAND_NAME],
      [MAKEUP_FIELDS.NAME_FROM_BRAND]: productForm.values[MAKEUP_FIELDS.NAME_FROM_BRAND],
      [MAKEUP_FIELDS.FRAGRANCE_NUMBER]: productForm.values[MAKEUP_FIELDS.FRAGRANCE_NUMBER]
    };
    setMakeupFeatures(makeupFeaturesPayload);
  }, [ // eslint-disable-line react-hooks/exhaustive-deps
    part,
    type,
    color,
    productForm.values
    // productForm.values[MAKEUP_FIELDS.BRAND_NAME],
    // productForm.values[MAKEUP_FIELDS.NAME_FROM_BRAND],
    // productForm.values[MAKEUP_FIELDS.FRAGRANCE_NUMBER]
  ]);

  useEffect(() => {
    setIsProductFormValid(debouncedProductFormIsValid);
  }, [debouncedProductFormIsValid]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    productForm.validateForm();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <FormikProvider value={ productForm }>
      <StyledForm
        autoComplete="off"
        noValidate
      >
        <Stack spacing={ 3 }>
          <Typography variant="h6">
            Makeup Features
          </Typography>
          <FieldsContainer spacing={ 1 }>
            <Stack spacing={ 1 }>
              <Typography
                variant="subtitle1"
                component="p"
              >
                Type
              </Typography>
              <Select
                defaultValue={ type }
                value={ type }
                components={{ Option: SelectOption }}
                options={ PRODUCT_ENUM.makeup_types }
                placeholder="Search..."
                onChange={ onTypeChange }
                closeMenuOnSelect={ false }
                noOptionsMessage={ () => 'No products found' }
              />
            </Stack>
            <Stack spacing={ 1 }>
              <Typography
                variant="subtitle1"
                component="p"
              >
                Part
              </Typography>
              <Select
                defaultValue={ part }
                value={ part }
                components={{ Option: SelectOption }}
                options={ PRODUCT_ENUM.part_of_the_face }
                placeholder="Search..."
                onChange={ setPart }
                closeMenuOnSelect={ false }
                noOptionsMessage={ () => 'No products found' }
              />
            </Stack>
            <Stack spacing={ 1 }>
              <Typography
                variant="subtitle1"
                component="p"
              >
                Brand Name
              </Typography>
              <TextField
                fullWidth
                type="text"
                { ...getFieldProps(MAKEUP_FIELDS.BRAND_NAME) }
              />
            </Stack>
            <Stack spacing={ 1 }>
              <Typography
                variant="subtitle1"
                component="p"
              >
                Name from brand
              </Typography>
              <TextField
                fullWidth
                type="text"
                { ...getFieldProps(MAKEUP_FIELDS.NAME_FROM_BRAND) }
              />
            </Stack>
            <Stack spacing={ 1 }>
              <Typography
                variant="subtitle1"
                component="p"
              >
                Fragrance Number
              </Typography>
              <TextField
                fullWidth
                type="text"
                { ...getFieldProps(MAKEUP_FIELDS.FRAGRANCE_NUMBER) }
              />
            </Stack>
            {
              transformedColors && (
                <Stack spacing={ 1 }>
                  <Typography
                    variant="subtitle1"
                    component="p"
                  >
                    Color
                  </Typography>
                  <Select
                    defaultValue={ color }
                    value={ color }
                    components={{ Option: SelectOption }}
                    options={ transformedColors }
                    placeholder="Search..."
                    onChange={ setColor }
                    closeMenuOnSelect={ false }
                    noOptionsMessage={ () => 'No products found' }
                  />
                </Stack>
              )
            }
          </FieldsContainer>
        </Stack>
      </StyledForm>
    </FormikProvider>
  );
};

const SkinCareFeatureFields = ({
  product,
  setSkinCareFeatures,
  setIsProductFormValid
}) => {
  // TODO: the place where the magic shit its pants. Check the product prop. Looks like it is defined much later, but now... Anyway, test carefully
  // const [type, setType] = useState(product.type?.map(t => ({ value: t, label: t })));
  // console.log('Lets show the value of the product const:', product)

  const [type, setType] = useState([])
  const [place, setPlace] = useState(PRODUCT_ENUM.skin_care_place[0]);
  const [subType, setSubType] = useState(PRODUCT_ENUM.skin_care_sub_type[0]);

  const ProductSchema = Yup.object().shape({
    [SKIN_CARE_FIELDS.BRAND_NAME]: Yup.string().required('This field is required!'),
    [SKIN_CARE_FIELDS.NAME_FROM_BRAND]: Yup.string().required('This field is required!'),
  });

  const productForm = useFormik({
    initialValues: {
      [SKIN_CARE_FIELDS.BRAND_NAME]: product?.brand_name ?? '',
      [SKIN_CARE_FIELDS.NAME_FROM_BRAND]: product?.name_from_brand ?? '',
      [SKIN_CARE_FIELDS.ACTIVE_COMPONENTS]: product?.active_components ?? '',
      [SKIN_CARE_FIELDS.APPLYING_METHOD]: product?.applying_method ?? '',
      [SKIN_CARE_FIELDS.COMPOUND]: product?.compound ?? '',
      [SKIN_CARE_FIELDS.SKIN_TYPE]: product?.skin_type ?? '',
    },
    validationSchema: ProductSchema,
  });

  const debouncedProductFormIsValid = useDebounce(productForm.isValid);

  const { getFieldProps } = productForm;

  useEffect(() => {
    const skincareFeaturesPayload = {
      [SKIN_CARE_FIELDS.TYPE]: type?.map(element => element.value),
      [SKIN_CARE_FIELDS.PLACE]: place?.value,
      [SKIN_CARE_FIELDS.SUB_TYPE]: subType?.value,
      [SKIN_CARE_FIELDS.BRAND_NAME]: productForm.values[SKIN_CARE_FIELDS.BRAND_NAME],
      [SKIN_CARE_FIELDS.NAME_FROM_BRAND]: productForm.values[SKIN_CARE_FIELDS.NAME_FROM_BRAND],
      [SKIN_CARE_FIELDS.ACTIVE_COMPONENTS]: productForm.values[SKIN_CARE_FIELDS.ACTIVE_COMPONENTS],
      [SKIN_CARE_FIELDS.APPLYING_METHOD]: productForm.values[SKIN_CARE_FIELDS.APPLYING_METHOD],
      [SKIN_CARE_FIELDS.COMPOUND]: productForm.values[SKIN_CARE_FIELDS.COMPOUND],
      [SKIN_CARE_FIELDS.SKIN_TYPE]: productForm.values[SKIN_CARE_FIELDS.SKIN_TYPE],

    };

    setSkinCareFeatures(skincareFeaturesPayload);
  }, [ // eslint-disable-line react-hooks/exhaustive-deps
    type,
    place,
    subType,
    productForm.values
    // productForm.values[SKIN_CARE_FIELDS.BRAND_NAME],
    // productForm.values[SKIN_CARE_FIELDS.NAME_FROM_BRAND]
  ]);

  useEffect(() => {
    setIsProductFormValid(debouncedProductFormIsValid);
  }, [debouncedProductFormIsValid]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    productForm.validateForm();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [valueSkinTab, setValueSkinTab] = React.useState('1');

  const handleChangeSkinTab = (event, newValue) => {
    setValueSkinTab(newValue);
  };

  return (
    <FormikProvider value={ productForm }>
      <StyledForm
        autoComplete="off"
        noValidate
      >
        <Stack spacing={ 3 }>
          <Typography variant="h6">
            Skin Care Features
          </Typography>
          <FieldsContainer spacing={ 1 }>
            <Stack spacing={ 1 }>
              <Typography
                variant="subtitle1"
                component="p"
              >
                Place
              </Typography>
              <Select
                defaultValue={ place }
                value={ place }
                components={{ Option: SelectOption }}
                options={ PRODUCT_ENUM.skin_care_place }
                placeholder="Search..."
                onChange={ setPlace }
                closeMenuOnSelect={ false }
                noOptionsMessage={ () => 'No products found' }
              />
            </Stack>
            <Stack spacing={ 1 }>
              <Typography
                variant="subtitle1"
                component="p"
              >
                Sub Type
              </Typography>
              <Select
                defaultValue={ subType }
                value={ subType }
                components={{ Option: SelectOption }}
                options={ PRODUCT_ENUM.skin_care_sub_type }
                placeholder="Search..."
                onChange={ setSubType }
                closeMenuOnSelect={ false }
                noOptionsMessage={ () => 'No products found' }
              />
            </Stack>
            <Stack spacing={ 1 }>
              <Typography
                variant="subtitle1"
                component="p"
              >
                Type
              </Typography>
              <Select
                defaultValue={ type }
                value={ type }
                components={{ Option: SelectOption }}
                options={ PRODUCT_ENUM.skin_care_type }
                placeholder="Search..."
                onChange={ setType }
                closeMenuOnSelect={ false }
                noOptionsMessage={ () => 'No products found' }
                isMulti
              />
            </Stack>
            <Stack spacing={ 1 }>
              <Typography
                variant="subtitle1"
                component="p"
              >
                Brand Name
              </Typography>
              <TextField
                fullWidth
                type="text"
                { ...getFieldProps(MAKEUP_FIELDS.BRAND_NAME) }
              />
            </Stack>
            <Stack spacing={ 1 }>
              <Typography
                variant="subtitle1"
                component="p"
              >
                Name from brand
              </Typography>
              <TextField
                fullWidth
                type="text"
                { ...getFieldProps(MAKEUP_FIELDS.NAME_FROM_BRAND) }
              />
            </Stack>
          </FieldsContainer>
        </Stack>
      </StyledForm>
     <StyledForm>
        <Typography variant="h6">
          Fragrance Multi-language fields
        </Typography>

        <TabContext value={valueSkinTab}>
          <Box sx={{ width: "100%"}}>
            <TabList onChange={handleChangeSkinTab}>
              <Tab label="text_RO" value="1" />
              <Tab label="text_RU" value="2" />
              <Tab label="text_EN" value="3" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <Typography variant="subtitle1" component="p">Active components RO</Typography>
            <TextField style={{margin: "15px" }} multiline maxRows={10} fullWidth type="text"  { ...getFieldProps(SKIN_CARE_FIELDS.ACTIVE_COMPONENTS_RO) }/>
            <Typography variant="subtitle1" component="p">Applying method  RO</Typography>
            <TextField style={{margin: "15px" }} multiline maxRows={10} fullWidth type="text"  { ...getFieldProps(SKIN_CARE_FIELDS.APPLYING_METHOD_RO) } />
            <Typography variant="subtitle1" component="p">Compound   RO</Typography>
            <TextField style={{margin: "15px" }} multiline maxRows={30} fullWidth type="text"  { ...getFieldProps(SKIN_CARE_FIELDS.COMPOUND_RO) } />
            <Typography variant="subtitle1" component="p">Skin type RO</Typography>
            <TextField style={{margin: "15px" }} multiline maxRows={10} fullWidth type="text"  { ...getFieldProps(SKIN_CARE_FIELDS.SKIN_TYPE_RO) } />
          </TabPanel>
          <TabPanel value="2">
            <Typography variant="subtitle1" component="p">Active components RU</Typography>
            <TextField style={{margin: "15px" }} multiline maxRows={10} fullWidth type="text" { ...getFieldProps(SKIN_CARE_FIELDS.ACTIVE_COMPONENTS_RU) } />
            <Typography variant="subtitle1" component="p">Applying method  RU</Typography>
            <TextField style={{margin: "15px" }} multiline maxRows={10} fullWidth type="text" { ...getFieldProps(SKIN_CARE_FIELDS.APPLYING_METHOD_RU) } />
            <Typography variant="subtitle1" component="p">Compound  RU</Typography>
            <TextField style={{margin: "15px" }} multiline maxRows={30} fullWidth type="text" { ...getFieldProps(SKIN_CARE_FIELDS.COMPOUND_RU) } />
            <Typography variant="subtitle1" component="p">Skin type RU</Typography>
            <TextField style={{margin: "15px" }} multiline maxRows={10} fullWidth type="text" { ...getFieldProps(SKIN_CARE_FIELDS.SKIN_TYPE_RU) } />
          </TabPanel>
          <TabPanel value="3">
            <Typography variant="subtitle1" component="p">Active components EN</Typography>
            <TextField style={{margin: "15px" }} multiline maxRows={10} fullWidth type="text" { ...getFieldProps(SKIN_CARE_FIELDS.ACTIVE_COMPONENTS_EN) } />
            <Typography variant="subtitle1" component="p"> Applying method EN</Typography>
            <TextField style={{margin: "15px" }} multiline maxRows={10} fullWidth type="text" { ...getFieldProps(SKIN_CARE_FIELDS.APPLYING_METHOD_EN) } />
            <Typography variant="subtitle1" component="p"> Compound EN</Typography>
            <TextField style={{margin: "15px" }} multiline maxRows={30} fullWidth type="text" { ...getFieldProps(SKIN_CARE_FIELDS.COMPOUND_EN) } />
            <Typography variant="subtitle1" component="p">Skin type EN</Typography>
            <TextField style={{margin: "15px" }} multiline maxRows={10} fullWidth type="text"  { ...getFieldProps(SKIN_CARE_FIELDS.SKIN_TYPE_EN) }/>
          </TabPanel>
        </TabContext>
      </StyledForm>
    </FormikProvider>
  );
};

const CandlesFeatureFields =({
  product,
  setCandlesFeatures,
  setIsProductFormValid
}) => {

  const ProductSchema = Yup.object().shape({
    [CANDLE_FIELDS.TYPE_RU]: Yup.string().required('This field is required!'),

  });

  const productForm = useFormik({
    initialValues: {
      [CANDLE_FIELDS.TYPE_RU]: product?.fragrance_type.ru ?? '',
      [CANDLE_FIELDS.TYPE_RO]: product?.fragrance_type.ro ?? '',
      [CANDLE_FIELDS.TYPE_EN]: product?.fragrance_type.en ?? '',
      [CANDLE_FIELDS.TYPE]: product?.fragrance_type?? '',
      [CANDLE_FIELDS.COMPOUND]: product?.compound ?? '',
      [CANDLE_FIELDS.COMPOUND_RO]: product?.compound.ro?? '',
      [CANDLE_FIELDS.COMPOUND_RU]: product?.compound.ru ?? '',
      [CANDLE_FIELDS.COMPOUND_EN]: product?.compound.en ?? '',
    },
    validationSchema: ProductSchema,
  });
  const debouncedProductFormIsValid = useDebounce(productForm.isValid);
  const { getFieldProps } = productForm;

  console.log(productForm.isValid)

  useEffect(() => {
    // console.log("👉 fragranceTypes", fragranceTypes, "👈")
    const candleFeaturesPayload = {
      [CANDLE_FIELDS.TYPE_EN]: productForm.values[CANDLE_FIELDS.TYPE_EN],
      [CANDLE_FIELDS.TYPE_RO]: productForm.values[CANDLE_FIELDS.TYPE_RO],
      [CANDLE_FIELDS.TYPE_RU]: productForm.values[CANDLE_FIELDS.TYPE_RU],
      [CANDLE_FIELDS.COMPOUND_RO]: productForm.values[CANDLE_FIELDS.COMPOUND_RO],
      [CANDLE_FIELDS.COMPOUND_RU]: productForm.values[CANDLE_FIELDS.COMPOUND_RU],
      [CANDLE_FIELDS.COMPOUND_EN]: productForm.values[CANDLE_FIELDS.COMPOUND_EN],
    };

    setCandlesFeatures(candleFeaturesPayload);
  }, [

    setCandlesFeatures,
    productForm.values,
  ]);

  useEffect(() => {
    setIsProductFormValid(debouncedProductFormIsValid);
  }, [debouncedProductFormIsValid, setIsProductFormValid]);

  useEffect(() => {
    productForm.validateForm();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  const [candleTab, setCandleTab] = React.useState('1');

  const handleChangeCandleTab = (event, newValue) => {
    setCandleTab(newValue);
  };

  useEffect(() => {
    productForm.validateForm();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  return (
      <FormikProvider value={productForm}>
        <StyledForm
            autoComplete="off"
            noValidate
        >
          <Typography variant="h6">
            Fragrance Multi-language fields
          </Typography>

          <TabContext value={candleTab}>
            <Box sx={{ width: "100%"}}>
              <TabList onChange={handleChangeCandleTab}>
                <Tab label="text_RO" value="1" />
                <Tab label="text_RU" value="2" />
                <Tab label="text_EN" value="3" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <Typography variant="subtitle1" component="p">Candles type RO</Typography>
              <TextField style={{margin: "15px" }} multiline maxRows={10} fullWidth type="text" {...getFieldProps(CANDLE_FIELDS.TYPE_RO)} />
              <br/>
              <Typography variant="subtitle1" component="p">Candles Compound RO</Typography>
              <TextField style={{margin: "15px" }} multiline maxRows={10} fullWidth type="text"  { ...getFieldProps(CANDLE_FIELDS.COMPOUND_RO) }/>
            </TabPanel>
            <TabPanel value="2">
              <Typography variant="subtitle1" component="p">Candles type RU</Typography>
              <TextField style={{margin: "15px" }} multiline maxRows={10} fullWidth type="text"  { ...getFieldProps(CANDLE_FIELDS.TYPE_RU) }/>
              <br/>
              <Typography variant="subtitle1" component="p">Candles Compound RU</Typography>
              <TextField style={{margin: "15px" }} multiline maxRows={10} fullWidth type="text"  { ...getFieldProps(CANDLE_FIELDS.COMPOUND_RU) }/>
            </TabPanel>
            <TabPanel value="3">
              <Typography variant="subtitle1" component="p">Candles type EN</Typography>
              <TextField style={{margin: "15px" }} multiline maxRows={10} fullWidth type="text"  { ...getFieldProps(CANDLE_FIELDS.TYPE_EN) }/>
              <Typography variant="subtitle1" component="p">Candles Compound EN</Typography>
              <TextField style={{margin: "15px" }} multiline maxRows={10} fullWidth type="text"  { ...getFieldProps(CANDLE_FIELDS.COMPOUND_EN) }/>
            </TabPanel>
          </TabContext>
        </StyledForm>
      </FormikProvider>
  );

};


// Exports
function ProductEditor ({ productId, productType: initialProductType }) {
  const [errorMessage, setErrorMessage] = useState(null);
  const [isProductFormValid, setIsProductFormValid] = useState(false);

  const [productImages, setProductImages] = useState([]);
  const [productType, setProductType] = useState(initialProductType);
  const [isFormReady, setIsFormReady] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const editorRef = useRef(null);
  const fileLoaderRef = useRef(null);

  const [valueDescription, setValueDescription] = React.useState("2");

  // const [descriptionRO, setDescriptionRO]=useState(product?.description);
  // const [descriptionRU, setDescriptionRU]=useState(product.description.ru);
  // const [descriptionEN, setDescriptionEN]=useState(product.description.en);
  //
  // const productDescription = {"ro": descriptionRO, "ru": descriptionRU, "en": descriptionEN}

  const handleChangeDescription = (event, newValue) => {
    setValueDescription(newValue);
  };

  const { createProduct } = useProductList(productType);
  const {
    data: product,
    deleteProduct,
    updateProduct
  } = useProduct(productType, productId);
  const [productName, setProductName] = useState(product?.name || product?.name_from_brand || '');


  const [descriptionRO, setDescriptionRO]=useState(product?.description.ro || '');
  const [descriptionRU, setDescriptionRU]=useState(product?.description.ru || '');
  const [descriptionEN, setDescriptionEN]=useState(product?.description.en || '');

  const productDescription = {"ro": descriptionRO, "ru": descriptionRU, "en": descriptionEN}

  console.log("👉 productId", product, productId, "👈")
  // Features
  const [makeupFeatures, setMakeupFeatures] = useState(null);
  const [skinCareFeatures, setSkinCareFeatures] = useState(null);
  const [candlesFeatures, setCandlesFeatures] = useState(null);
  const [fragranceFeatures, setFragranceFeatures] = useState(null);

  const { navigate } = useNavigation();

  const handleDeleteProduct = async () => {
    setIsDeleting(true);
    await deleteProduct({
      productType,
      productId
    });
    setIsDeleting(false);
    await navigate(`/dashboard/products?productType=${productType}`);
  };

  const handleFormSubmit = async () => {
    setErrorMessage(null);
    setIsSubmitting(true);
    console.log("👉 makeupFeatures", makeupFeatures, "👈");
    console.log("👉 skinCareFeatures", skinCareFeatures, "👈");
    console.log("👉 fragranceFeatures", fragranceFeatures, "👈");
    console.log("👉 candlesFeatures", candlesFeatures, "👈");

    let productPayload = {
      name: productName,
      description: productDescription,
    };

    console.log("👉 Features", makeupFeatures, productPayload, candlesFeatures, skinCareFeatures, fragranceFeatures, "👈");
    console.log("👉 Payload", productPayload, "👈");

    if (productType === PRODUCT_TYPES.MAKEUP) {
      productPayload = { ...productPayload, ...makeupFeatures };
    } else if (productType === PRODUCT_TYPES.SKINCARE) {
      productPayload = { ...productPayload, ...skinCareFeatures };
    } else if (productType === PRODUCT_TYPES.CANDLES) {
      productPayload = { ...productPayload, ...candlesFeatures };

    }
    else {
      productPayload = { ...productPayload, ...fragranceFeatures };
    }

    try {
      if (productId) {
        //  update product
        await updateProduct(productPayload);
      } else {
        // create product
        await createProduct({
          productType,
          product: productPayload
        });
      }
    } catch (e) {
      setIsSubmitting(false);
      // throw Error(e);
      console.log("👉", 'product catch', e.detail, "👈")
      setErrorMessage(e.detail);
      return false;
    }

    await navigate(`/dashboard/products?productType=${productType}`);

    return false;
  };

  useEffect(() => {

    if (isProductFormValid) {

      // if (productName.trim().length === 0) {
      if (productName.trim().length === 0 || descriptionRO.length === 0 || descriptionRU.length === 0 || descriptionEN.length === 0 ) {

        setIsFormReady(false);
      } else {
        setIsFormReady(true);
      }
    } else {
      setIsFormReady(false);
    }
  }, [
    isProductFormValid,
    productName,
    descriptionRO,
    descriptionRU,
    descriptionEN,
  ]);

  return (
    <Page title="Dashboard: Product">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={ 5 }
        >
          <Typography variant="h4" component="div">
            { productId ? 'Edit Product' : 'Create Product' }
            {
              productId && (
                <Typography variant="subtitle2" component="div">
                  ID: { productId }
                </Typography>
              )
            }
          </Typography>
          {
            productId && (
              <LoadingButton
                startIcon={
                  <Iconify
                    icon="mdi:delete-outline"
                    width={ 22 }
                    height={ 22 }
                  />
                }
                color="error"
                size="large"
                type="submit"
                variant="contained"
                onClick={ handleDeleteProduct }
                loading={ isDeleting }
              >
                Delete Product
              </LoadingButton>
            )
          }
        </Stack>
        <Stack spacing={ 6 }>
          <FieldsContainer>
            <Stack spacing={ 1 }>
              <Typography
                variant="subtitle1"
                component="p"
              >
                Product name
              </Typography>
              <TextField
                fullWidth
                autoComplete="name"
                type="text"
                value={ productName }
                onChange={ (event) => setProductName(event.target.value) }
              />
            </Stack>
          </FieldsContainer>
          {
            !productId && (
              <Stack>
                <Typography
                  variant="subtitle1"
                  component="p"
                >
                  Product type
                </Typography>
                <RadioGroup
                  row
                  name="product-type"
                  defaultValue={ productType }
                  value={ productType }
                  onChange={ (event) => setProductType(event.target.value) }
                >
                  <FormControlLabel
                    value={ PRODUCT_TYPES.FRAGRANCE }
                    control={ <Radio /> }
                    label={ ROOT_CATEGORIES[PRODUCT_TYPES.FRAGRANCE] }
                  />
                  <FormControlLabel
                    value={ PRODUCT_TYPES.MAKEUP }
                    control={ <Radio /> }
                    label={ ROOT_CATEGORIES[PRODUCT_TYPES.MAKEUP] }
                  />
                  <FormControlLabel
                    value={ PRODUCT_TYPES.SKINCARE }
                    control={ <Radio /> }
                    label={ ROOT_CATEGORIES[PRODUCT_TYPES.SKINCARE] }
                  />
                  <FormControlLabel
                      value={ PRODUCT_TYPES.CANDLES }
                      control={ <Radio /> }
                      label={ ROOT_CATEGORIES[PRODUCT_TYPES.CANDLES] }
                  />
                </RadioGroup>
              </Stack>
            )
          }
          {
            productType === PRODUCT_TYPES.FRAGRANCE && (
              <FragranceFeatureFields
                product={ product }
                setFragranceFeatures={ setFragranceFeatures }
                setIsProductFormValid={ setIsProductFormValid }
              />
            )
          }
          {
            productType === PRODUCT_TYPES.MAKEUP && (
              <MakeupFeatureFields
                product={ product }
                setMakeupFeatures={ setMakeupFeatures }
                setIsProductFormValid={ setIsProductFormValid }
              />
            )
          }
          {
            productType === PRODUCT_TYPES.SKINCARE && (
              <SkinCareFeatureFields
                product={ product }
                setSkinCareFeatures={ setSkinCareFeatures }
                setIsProductFormValid={ setIsProductFormValid }
              />
            )
          }
          {
            productType === PRODUCT_TYPES.CANDLES && (
                <CandlesFeatureFields
                    product={ product }
                    setCandlesFeatures={ setCandlesFeatures }
                    setIsProductFormValid={ setIsProductFormValid }
                />
            )
          }
          <Stack spacing={ 1 }>
            <Typography
              variant="subtitle1"
              component="p"
            >
              Product Images (max { MAX_FILES })
            </Typography>
            <FileLoader
              ref={ fileLoaderRef }
              maxFiles={ MAX_FILES }
              files={ productImages }
              setFiles={ setProductImages }
              label="Drag & Drop product images or <span class='filepond--label-action'>Browse</span>"
            />
          </Stack>
          {/*<ReactQuillContainer spacing={ 1 }>*/}
          {/*  <Typography*/}
          {/*    variant="subtitle1"*/}
          {/*    component="p"*/}
          {/*  >*/}
          {/*    Product description*/}
          {/*  </Typography>*/}
          {/*  <ReactQuill*/}
          {/*    ref={ editorRef }*/}
          {/*    value={ productDescription.ru }*/}
          {/*    onChange={ setProductDescription}*/}
          {/*    modules={ EDITOR_MODULES }*/}
          {/*    formats={ EDITOR_FORMATS }*/}
          {/*  />*/}
          {/*</ReactQuillContainer>*/}

          <TabContext value={valueDescription}>
            <Box sx={{ width: "100%"}}>
              <TabList onChange={handleChangeDescription}>
                <Tab  style={{fontWeight: "600"}} label="Description RO" value="1" />
                <Tab  style={{fontWeight: "600"}} label="Description RU" value="2" />
                <Tab  style={{fontWeight: "600"}} label="Description EN" value="3" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <ReactQuillContainer spacing={ 1 }>
                <ReactQuill
                    ref={ editorRef }
                    value={ descriptionRO }
                    onChange={ setDescriptionRO}
                    modules={ EDITOR_MODULES }
                    formats={ EDITOR_FORMATS }
                />
              </ReactQuillContainer>
            </TabPanel>
            <TabPanel value="2">
              <ReactQuillContainer spacing={ 1 }>
                <ReactQuill
                    ref={ editorRef }
                    value={ descriptionRU }
                    onChange={ setDescriptionRU}
                    modules={ EDITOR_MODULES }
                    formats={ EDITOR_FORMATS }
                />
              </ReactQuillContainer>
            </TabPanel>
            <TabPanel value="3">
              <ReactQuillContainer spacing={ 1 }>
                <ReactQuill
                    ref={ editorRef }
                    value={ descriptionEN }
                    onChange={ setDescriptionEN}
                    modules={ EDITOR_MODULES }
                    formats={ EDITOR_FORMATS }
                />
              </ReactQuillContainer>
            </TabPanel>
          </TabContext>

          {
            errorMessage && (
              <Typography sx={{ mt: '12px', mb: '12px' }} color="red">
                { JSON.stringify(errorMessage) }
              </Typography>
            )
          }
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <LoadingButton
              disabled={ !isFormReady }
              startIcon={
                !productId ? <Iconify
                  icon="ic:baseline-add"
                  width={ 22 }
                  height={ 22 }
                /> : null
              }
              color="secondary"
              size="large"
              type="submit"
              variant="contained"
              loading={ isSubmitting }
              onClick={ handleFormSubmit }
            >
              { productId ? 'Save Product' : 'Create Product' }
            </LoadingButton>
          </Box>
        </Stack>
      </Container>
    </Page>
  );
}

ProductEditor.propTypes = {
  productId: PropTypes.string,
  productType: PropTypes.string
}

export default ProductEditor;

// Package modules
import React, {useEffect, useState} from 'react';
import {
    Button,
    TextField,
    Typography, Grid
} from '@mui/material';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {styled} from "@mui/material/styles";

// Local modules
import formik, { useFormik} from 'formik';
import {usePartners} from '../lib/state';
import {addComment, api, postWarehouseNr, rejectPartner} from "../lib/api";

const StyledForm = styled('div')`
  background-color: white;
  border: 1px dashed ${({ theme }) => theme.palette.grey['50032']};
  border-radius: 8px;
  padding: 24px;
  width: 93%;
`;

const StyledForm2 = styled('div')`
  background-color: white;
  border: 1px dashed ${({ theme }) => theme.palette.grey['50032']};
  border-radius: 8px;
  padding: 24px;
  width: 60%;
`;


// Exports
function PartnerRequest(partnerRequestId) {
    const { data } = usePartners();
    const itemNew = data?.new.filter(x => x.id === partnerRequestId.partnerRequestId);
    const itemWarehouseAssigned = data?.warehouseAssigned.filter(x => x.id === partnerRequestId.partnerRequestId);
    const itemSendedToOneC = data?.sendedToOneC.filter(x => x.id === partnerRequestId.partnerRequestId);
    const itemRejected = data?.rejected.filter(x => x.id === partnerRequestId.partnerRequestId);
    const item = { ...itemNew, ...itemWarehouseAssigned, ...itemRejected, ...itemSendedToOneC};
    // const item = Object.assign( itemNew, itemWarehouseAssigned, itemRejected, itemSendedToOneC );
    const newPartner = item[0];
    const newPartnerId = newPartner.id
    const [commentAll, setCommentAll] = useState(newPartner.comment);

    const getWarehouseList = () => api.warehouseList.getWarehouseList();

    const [nr, setNr] = React.useState('');
    const [nrWareHouse, setNrWareHouse] = React.useState('');

    useEffect(() => {
        if (!nr)
            getWarehouseList()
                .then((body) => {
                    setNr(body);
                })
                .catch((err_data) => {
                    console.log("no data", err_data);
                })
    }, []);

    const handleChange = (event) => {
        setNrWareHouse(event.target.value);
    };

    const handleSubmitWhouse = evt => {
        evt.preventDefault();
        postWarehouseNr(nrWareHouse, newPartnerId)
            .then(response => response.json())
            .then(response => console.log("Success:", JSON.stringify(response)))
            .catch(error => console.error("Error:", error));
    };

    const handleSubmitRejected = evt => {
        evt.preventDefault();
        rejectPartner(newPartnerId)
            .then(response => response.json())
            .then(response => console.log("Success:", JSON.stringify(response)))
            .catch(error => console.error("Error:", error));

    }

    const formik = useFormik({
        initialValues: {
            text: '',
        },
        onSubmit:values =>
    {
        addComment(newPartnerId, values)
            .then(response => response.json())
            .then((res) => {
                setCommentAll(res.data.comment);
                console.log("Success:", res.data, res.data.comment);
            })
            .catch(error => console.error("Error:", error));
    }
    });

    return (
        <>
            <Box sx={{ p: 2}}>
                <Grid container>
                <Grid item xs={12} md={4}>
                    <Typography variant="h6">
                       {newPartner.full_name}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={2}>

                    { newPartner.status === 'new'
                        && <Button variant="contained" style={{backgroundColor: '#fff9c4', color: 'black'}}>
                    {!newPartner.status ? 'undefined' : newPartner.status}
                    </Button>}

                    {newPartner.status === 'warehouseAssigned'
                        && <Button variant="contained" style={{backgroundColor: '#3366ff', color: 'white'}}>
                    {!newPartner.status ? 'undefined' : newPartner.status}
                        </Button> }

                    { newPartner.status === 'rejected'
                        && <Button variant="contained" style={{backgroundColor: 'red', color: 'white'}}>
                        {!newPartner.status ? 'undefined' : newPartner.status}
                    </Button> }

                    { newPartner.status === 'sendedToOneC'
                        && <Button variant="contained" style={{backgroundColor: 'green', color: 'white'}}>
                        {!newPartner.status ? 'undefined' : newPartner.status}
                    </Button>
                    }
                </Grid>
            </Grid>
            <div>Registration Date - {newPartner.registration_date.split("T")[0]}</div>
            </Box>

            <StyledForm>
                <Grid container spacing={1}>
                    <Grid item md={3} xs={11}>
                        <br/>
                        <br/>
                        <TextField
                            type="text"
                            label="Country"
                            defaultValue={newPartner.country}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                        <br/>
                        <br/>
                        <TextField
                            label="Phone"
                            defaultValue={newPartner.phone}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                        <br/>
                        <br/>
                        <TextField
                            label="Distributor"
                            defaultValue={newPartner.distributor}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                        <br/>
                        <br/>
                        <TextField
                            label="Birthday"
                            defaultValue={newPartner.birthday.split("T")[0]}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                        <br/>
                        <br/>
                        {!newPartner.warehouse ? <br/> :
                            <TextField
                                label="warehouse"
                                defaultValue={newPartner.warehouse}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        }
                    </Grid>
                    <Grid item md={3} xs={11}>
                        <br/>
                        <br/>
                        <TextField
                            type="text"
                            label="City"
                            defaultValue={newPartner.city}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                        <br/>
                        <br/>
                        <TextField
                            label="Email"
                            defaultValue={newPartner.email}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                        <br/>
                        <br/>
                        <TextField
                            label="Franchise"
                            defaultValue={newPartner.franchise}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                        <br/>
                        <br/>
                        <TextField
                            label="Referral"
                            defaultValue={newPartner.referral}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item md={6} xs={11}>
                        <h2 style={{textAlign: 'center'}}>Чат</h2>
                        <br/>
                        <Grid container spacing={2} style={{
                            borderStyle: 'solid',
                            borderWidth: '1px',
                            borderRadius: '8px',
                            borderColor: 'rgba(145, 158, 171, 0.32)',
                            height: '400px',
                            overflowY: 'auto'
                        }}>
                            {commentAll.map((item, index) => <Grid item xs={11} key={index}>
                                <div>{item.commentator}</div>
                                <div>{item.date.split("T")[0]}</div>
                                <div style={{
                                    background: 'lightblue',
                                    borderRadius: '8px',
                                    padding: '7px'
                                }}>{item.comment}</div>
                                <br/>
                            </Grid>)}
                        </Grid>
                    </Grid>
                </Grid>

                <h3>Отправить Сообщение</h3>
                <br/>
                <form onSubmit={formik.handleSubmit}>
                    <TextField
                        id="text"
                        label="Comment"
                        fullWidth
                        multiline
                        rows={6}
                        onChange={formik.handleChange}
                        value={formik.values.text}
                    />
                    <br/>
                    <br/>
                    <br/>
                    {newPartner.status === 'sendedToOneC' ? <Button variant="contained" disabled >Submit</Button> :
                    <Button type="submit" variant="contained" color="secondary">Submit</Button> }
                </form>
            </StyledForm>
            <br/>
            <br/>
            <StyledForm2>
                <h4>Назначить склад</h4>
                <br/>
                <Box sx={{minWidth: 120}}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Nr</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={nrWareHouse}
                            label="Nr"
                            onChange={handleChange}
                        >
                            {Array.from(nr).map((item) => <MenuItem key={item._id}
                                                                    value={item._id}>{item.user_id}</MenuItem>)}
                        </Select>
                        <br/>
                        <br/>
                    </FormControl>
                    {newPartner.status === 'rejected' && 'sendedToOneC' ?
                        <Button variant="contained" disabled>Submit</Button> :
                        <Button type="submit" variant="contained" onClick={handleSubmitWhouse}
                                color="secondary">Submit</Button>}
                </Box>
            </StyledForm2>
            <br/>
            <div style={{padding: '15px', textAlign: 'left'}}>
                {newPartner.status === 'rejected' && 'sendedToOneC' ? <div/> :
                    <Button variant="contained" color="error" onClick={handleSubmitRejected}>Rejected</Button>}
            </div>
            <br/>
            <br/>
        </>
    );
}

export default PartnerRequest;

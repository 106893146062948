// Package modules
import React, { useState, Suspense } from 'react';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { Link as RouterLink, useNavigation } from 'react-navi';
import {
  Stack,
  Button,
  Container,
  Typography
} from '@mui/material';

// Local modules
import Page from '../components/Page';
import Iconify from '../components/Iconify';
import { useProductList } from '../lib/state';
import { ProductList } from '../sections/@dashboard/products';
import { ROOT_CATEGORIES } from '../lib/constants';
import { ProgressCircular } from '../components/Progress';

const Products = ({ productType}) => { // eslint-disable-line react/prop-types
  const { data: products } = useProductList(productType);

  return (
    <ProductList
      products={ products }
      productType={ productType }
    />
  );
};

// Exports
export default function EcommerceShop({ productType }) {
  const [openFilter, setOpenFilter] = useState(false); // eslint-disable-line no-unused-vars

  console.log("👉 productType", productType, "👈")
  // const { data: products } = useProductList(productType);

  const { navigate } = useNavigation();

  const formik = useFormik({
    initialValues: {
      gender: '',
      category: '',
      colors: '',
      priceRange: '',
      rating: ''
    },
    onSubmit: () => {
      setOpenFilter(false);
    }
  });

  const { resetForm, handleSubmit } = formik;

  const handleOpenFilter = () => {// eslint-disable-line no-unused-vars
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {// eslint-disable-line no-unused-vars
    setOpenFilter(false);
  };

  const handleResetFilter = () => {// eslint-disable-line no-unused-vars
    handleSubmit();
    resetForm();
  };

  return (
    <Page title="Dashboard: Products">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={ 5 }
        >
          <Typography
            variant="h4"
            gutterBottom
          >
            { ROOT_CATEGORIES[productType] }
            &nbsp;
            Products
          </Typography>
          <Button
            variant="contained"
            component={ RouterLink }
            to="#"
            startIcon={ <Iconify icon="eva:plus-fill" /> }
            onClick={ () => navigate(`/dashboard/products/add?productType=${productType}`) }
          >
            Create Product
          </Button>
        </Stack>
        {/*<Stack*/}
        {/*  direction="row"*/}
        {/*  flexWrap="wrap-reverse"*/}
        {/*  alignItems="center"*/}
        {/*  justifyContent="flex-end"*/}
        {/*  sx={{ mb: 5 }}*/}
        {/*>*/}
        {/*  <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>*/}
        {/*    <ProductFilterSidebar*/}
        {/*      formik={formik}*/}
        {/*      isOpenFilter={openFilter}*/}
        {/*      onResetFilter={handleResetFilter}*/}
        {/*      onOpenFilter={handleOpenFilter}*/}
        {/*      onCloseFilter={handleCloseFilter}*/}
        {/*    />*/}
        {/*    <ProductSort />*/}
        {/*  </Stack>*/}
        {/*</Stack>*/}
        <Suspense fallback={ <ProgressCircular /> }>
          <Products productType={ productType } />
        </Suspense>
      </Container>
    </Page>
  );
}

EcommerceShop.propTypes = {
  productType: PropTypes.string
}

// Package modules
import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-navi';

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {
  return (
      <RouterLink to="/">
          <div style={{width: '250px', textAlign: 'center'}}>
              <img src="/static/logo/giter-world_1_crop.png" alt='logo'
                   style={{width: '160px', height: 'auto', display: 'block', margin: '0 auto'}}
              />
          </div>
      </RouterLink>
  );
}

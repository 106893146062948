import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Container,
  Grid,
  Tab, Tabs,
  TextField,
  Typography
} from '@mui/material';
import dayjs from 'dayjs';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LoadingButton } from '@mui/lab';
import { deleteMailLetter, editMaillettersById, getMailLettersById, newMailletters } from '../lib/api';
import Iconify from '../components/Iconify';
import Page from '../components/Page';
import { getCountry } from '../utils/getCountry';
import { getRang } from '../utils/getRang';

import EditorRU from '../components/reactQuillTab/EditorRU';
import EditorRO from '../components/reactQuillTab/EditorRO';
import EditorEN from '../components/reactQuillTab/EditorEN';
import EditorUA from '../components/reactQuillTab/EditorUA';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const MailLettersId = (maillettersId) => {
  const [mailLettersItem, setMailLettersItem] = useState('');
  const [countrySelect, setCountrySelect] = useState('');
  const [postContentRU, setPostContentRU] = useState('');
  const [postContentRO, setPostContentRO] = useState('');
  const [postContentEN, setPostContentEN] = useState('');
  const [postContentUA, setPostContentUA] = useState('');
  const [subjectRU, setSubjectRU] = useState('');
  const [subjectRO, setSubjectRO] = useState('');
  const [subjectEN, setSubjectEN] = useState('');
  const [subjectUA, setSubjectUA] = useState('');
  const [rangSelect, setRangSelect] = useState('');
  const [title, setTitle] = useState('');

  const [isFormReady, setIsFormReady] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState(''); // New state for success message
  const [deleteSuccessMessage, setDeleteSuccessMessage] = useState(''); // State for delete success message

  const [scheduleDate, setScheduleDate] = React.useState(dayjs(new Date()));

  useEffect(() => {
    getMailLettersById(maillettersId)
      .then(response => response.json())
      .then((data) => {
        console.log(data, 'dataCome');
        setMailLettersItem(data);
        setTitle(data?.name || '');
        setPostContentRU(data?.body?.RU?.text_html || '');
        setPostContentRO(data?.body?.RO?.text_html || '');
        setPostContentEN(data?.body?.EN?.text_html || '');
        setPostContentUA(data?.body?.UA?.text_html || '');
        setSubjectRU(data?.body?.RU?.subject || '');
        setSubjectRO(data?.body?.RO?.subject || '');
        setSubjectEN(data?.body?.EN?.subject || '');
        setSubjectUA(data?.body?.UA?.subject || '');
        setCountrySelect(data?.country || '');
        setRangSelect(data?.rang || 0);
        setScheduleDate(dayjs(data?.schedule_date));
      })
      .catch((err_data) => {
        console.log('no data', err_data);
      });
  }, [maillettersId]);


  const handleChange = (event) => {
    setCountrySelect(event.target.value);
  };

  const handleChangeRang = (event) => {
    setRangSelect(event.target.value);
  };

  const handleDeleteMailLetter = ({ maillettersId }) => {
    deleteMailLetter(maillettersId)
      .then(response => response.json())
      .then((response) => {
        setMailLettersItem(response);
        setDeleteSuccessMessage('The mail letter was deleted successfully!');
        console.log('Success delete:', response);
      })
      .catch(error => console.error('Error delete:', error));
  };

  const [tabIndex, setTabIndex] = useState(0);

  const payload = {
    name: title,
    country: countrySelect,
    rang: rangSelect,
    schedule_date: scheduleDate.toISOString(),
    body: {
      RU: {
        subject: subjectRU,
        text_html: postContentRU
      },
      RO: {
        subject: subjectRO,
        text_html: postContentRO
      },
      EN: {
        subject: subjectEN,
        text_html: postContentEN
      },
      UA: {
        subject: subjectUA,
        text_html: postContentUA
      }
    }
  };

  useEffect(() => {
    if (title.trim().length === 0 || postContentRU.trim().length === 0 || subjectRU.trim().length === 0) {
      setIsFormReady(false);
    } else {
      setIsFormReady(true);
    }
  }, [title, postContentRU, subjectRU]);

  const handleTabChangeEditor = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  const handleSubmit = () => {
    setIsSubmitting(true);
    setSuccessMessage(''); // Clear any previous success message
    setDeleteSuccessMessage(''); // Clear any previous delete success message


    const saveMailLetter = maillettersId.maillettersId
      ? editMaillettersById({ maillettersId, payload })
      : newMailletters({ payload });

    saveMailLetter
      .then(response => response.json())
      .then(() => {
        setIsSubmitting(false);
        setSuccessMessage('MailLetter was sent successfully!'); // Set success message
      })
      .catch(error => {
        setIsSubmitting(false);
        console.error('Error submitting:', error);
      });
  };

  return (
    <Page title="Dashboard: Blog Post">
      <Container>
        <Typography variant="h4" component="div">
          {maillettersId.maillettersId ? 'Edit Mail Letter' : 'Create Mail Letter'}
          {maillettersId.maillettersId ? <Typography variant="subtitle2" component="div">
            maillettersId: {mailLettersItem?._id}
          </Typography> : ''}
        </Typography>
        <br />
        <Box sx={{ p: 2 }}>
          <Grid container>
            {maillettersId.maillettersId ? <Grid item xs={12} md={8}>
              <div>Created Date - {mailLettersItem?.created_date?.split('T')[0]}</div>
              <div>Edit Date - {mailLettersItem?.edit_date?.split('T')[0]}</div>
            </Grid> : ''}
            {maillettersId.maillettersId ? <Grid item xs={12} md={2}>
              <b>Status</b> : {mailLettersItem?.status}
            </Grid> : ''}
          </Grid>
          <br />
          <Grid container>
            <Grid item xs={12} md={8}>
              <TextField
                fullWidth
                label="Title"
                value={title}
                onChange={(event) => setTitle(event.target.value)}
              />
            </Grid>
          </Grid>

        <br />
        <br />
        <Grid container spacing={1}>
          <Grid item md={6} xs={6}>
            <FormControl sx={{ minWidth: 120 }}>
              <InputLabel id="demo-simple-select-label">Country</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={countrySelect}
                label="Country"
                onChange={handleChange}
              >
                {getCountry.map((option) => (
                  <MenuItem key={option._id} value={option._id}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <div>&nbsp;</div>
            <FormControl sx={{ minWidth: 120 }}>
              <InputLabel id="demo-simple-select-label">Rang</InputLabel>
              <Select
                type="number"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={rangSelect}
                label="Rang"
                onChange={handleChangeRang}
              >
                {getRang.map((option) => (
                  <MenuItem key={option.id} value={option.name}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={6} xs={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Select Date"
                value={scheduleDate}
                onChange={(newValue) => setScheduleDate(newValue)}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={12} style={{background: '#919eab1f', marginTop: "40px", borderRadius: '12px'}}>
            <Tabs value={tabIndex} onChange={handleTabChangeEditor} aria-label="Language Tabs">
              <Tab label="RU" id="tab-0" />
              <Tab label="RO" id="tab-1" />
              <Tab label="EN" id="tab-2" />
              <Tab label="UA" id="tab-3" />
            </Tabs>
            <TabPanel value={tabIndex} index={0}>
              <TextField
                fullWidth
                label="Subject"
                value={subjectRU}
                onChange={(event) => setSubjectRU(event.target.value)}
              />
              <div>&nbsp;</div>
              <EditorRU
                value={postContentRU}
                onChange={setPostContentRU}
              />
            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
              <TextField
                fullWidth
                label="Subject"
                value={subjectRO}
                onChange={(event) => setSubjectRO(event.target.value)}
              />
              <div>&nbsp;</div>
              <EditorRO
                value={postContentRO}
                onChange={setPostContentRO}
              />
            </TabPanel>
            <TabPanel value={tabIndex} index={2}>
              <TextField
                fullWidth
                label="Subject"
                value={subjectEN}
                onChange={(event) => setSubjectEN(event.target.value)}
              />
              <div>&nbsp;</div>
              <EditorEN
                value={postContentEN}
                onChange={setPostContentEN}
              />
            </TabPanel>
            <TabPanel value={tabIndex} index={3}>
              <TextField
                fullWidth
                label="Subject"
                value={subjectUA}
                onChange={(event) => setSubjectUA(event.target.value)}
              />
              <div>&nbsp;</div>
              <EditorUA
                value={postContentUA}
                onChange={setPostContentUA}
              />
            </TabPanel>
          </Grid>
        </Grid>
        </Box>
        <br />
        <br />
        {successMessage && (
          <Typography variant="body1" color="success.main" gutterBottom>
            {successMessage}
          </Typography>
        )}
        {deleteSuccessMessage && ( // Display delete success message
          <Typography variant="body1" color="error.main" gutterBottom>
            {deleteSuccessMessage}
          </Typography>
        )}
        <br />
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              color="secondary"
              variant="contained"
              loading={isSubmitting}
              disabled={!isFormReady}
              onClick={handleSubmit}
              startIcon={<Iconify icon="mdi:email-send" />}
            >
              {maillettersId.maillettersId ? 'Save' : 'Send'}
            </LoadingButton>
          </Grid>
          <Grid item xs={12} md={5}>&nbsp;</Grid>
          <Grid item xs={12} md={3}>
            {maillettersId.maillettersId ? (
              <LoadingButton
                fullWidth
                size="large"
                variant="outlined"
                color="error"
                onClick={() => handleDeleteMailLetter({maillettersId})}
                startIcon={<Iconify icon="eva:close-outline" />}
              >
                Close
              </LoadingButton>
            ) : ''}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default MailLettersId;

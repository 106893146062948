import { Component } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import PropTypes from 'prop-types';
import {Stack, styled} from "@mui/material";
import htmlEditButton from 'quill-html-edit-button';

Quill.register("modules/htmlEditButton", htmlEditButton);

const ReactQuillContainer = styled(Stack)`
  & .quill {
    background-color: ${({ theme }) => theme.palette.common.white};
    border-radius: 8px;
  }
  
  & .ql-toolbar.ql-snow {
    border-color: ${ ({ theme }) => theme.palette.grey['50032'] };
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  
  & .ql-container.ql-snow {
    border-color: ${ ({ theme }) => theme.palette.grey['50032'] };
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  
  & .ql-editor {
    font-size: 1rem;
    min-height: 200px;
  }
`;


class EditorUA extends Component {
  componentDidMount() {
    // Update the custom dropdown items
    const placeholderPickerItems = Array.prototype.slice.call(
      document.querySelectorAll('.ql-placeholder .ql-picker-item')
    );
    placeholderPickerItems.forEach((item) => (item.textContent = item.dataset.value));

    document.querySelector('.ql-placeholder .ql-picker-label').innerHTML =
      `Variables  &nbsp; &nbsp;${  document.querySelector('.ql-placeholder .ql-picker-label').innerHTML}`;
  }


  render() {
    return (
      <ReactQuillContainer spacing={ 2 } style={{width: '100%'}}>
        <ReactQuill
          theme="snow"
          onChange={this.props.onChange}
          value={this.props.value}
          modules={EditorUA.modules}
          placeholder={this.props.placeholder}
        />
      </ReactQuillContainer>
    );
  }
}

/*
 * Quill modules to attach to editor
 * See http://quilljs.com/docs/modules/ for complete options
 */
EditorUA.modules = {
  toolbar: {
    container: [
      [{ placeholder: ['{{.UserId}}', '{{.UserName}}', '{{.UserFirstName}}', '{{.UserFullName}}', '{{.UserCountry}}', '{{.UserEmail}}'] }],
      [{'header': [1, 2, false]}],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      ['link', 'image', 'video'],
      [{list: 'ordered'}, {list: 'bullet'}],
      ['clean'], // Remove formatting button
    ],
    htmlEditButton: {},
    handlers: {
      placeholder(value) {
        if (value) {
          const cursorPosition = this.quill.getSelection().index;
          this.quill.insertText(cursorPosition, value);
          this.quill.setSelection(cursorPosition + value.length);
        }
      },
    },
  },
};
/*
 * PropType validation
 */
EditorUA.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export default EditorUA;
// Package modules
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-navi';
import { styled } from '@mui/material/styles';
import {
  Box,
  Card,
  Link,
  Stack,
  Typography
} from '@mui/material';
import { ColorPreview } from '../../../components/ColorPreview';

// Assets
import DummySrc from '../../../assets/dummy.jpg';

// Local modules
import Label from '../../../components/Label';

// Styled components
const ProductImgStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute'
});

// Exports
export default function ShopProductCard({ product, productType, ...remainingProps }) {
  const { name, name_from_brand, brand_name, status } = product;

  return (
    <Card { ...remainingProps }>
      <Box sx={{ pt: '100%', position: 'relative' }}>
        {
          status && (
            <Label
              variant="filled"
              color={ (status === 'sale' && 'error') || 'info' }
              sx={{
                zIndex: 9,
                top: 16,
                right: 16,
                position: 'absolute',
                textTransform: 'uppercase'
              }}
            >
              { status }
            </Label>
          )
        }
        <ProductImgStyle
          alt={ name || brand_name || name_from_brand }
          src={ product?.cover.cover ?? DummySrc }
        />
      </Box>
      <Stack
        spacing={ 2 }
        sx={{ p: 3 }}
      >
        <Link
          href={ `/dashboard/products/${ product.id }?productType=${ productType }` }
          color="inherit"
          underline="hover"
          component={ RouterLink }
        >
          <Typography
            variant="subtitle2"
            noWrap
          >
            { name || brand_name || name_from_brand }
          </Typography>
        </Link>
        {
          product?.color?.color && (
            <Stack
              sx={{
                position: 'absolute',
                top: '0px',
                right: '12px'
              }}
            >
              <ColorPreview
                colors={ [product?.color?.color] }
                limit={ 2 }
              />
            </Stack>
          )
        }
      </Stack>
    </Card>
  );
}

ShopProductCard.propTypes = {
  product: PropTypes.object,
  productType: PropTypes.string
};

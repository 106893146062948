// Package modules
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  useCurrentRoute,
  Link as RouterLink
} from 'react-navi';
import { styled } from '@mui/material/styles';
import {
  Box,
  Link,
  Drawer,
  Avatar,
  Typography
} from '@mui/material';

// Local modules
import Logo from '../../components/Logo';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
import { useResponsive } from '../../lib/utils';
import { useAuthService } from '../../lib/state';
import { SIDEBAR_CONFIG } from '../../lib/constants';

// Constants
const DRAWER_WIDTH = 280;

// Styled components
const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH
  }
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12]
}));

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func
};

// Exports
export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { url: { pathname } } = useCurrentRoute();
  const { authenticatedUser } = useAuthService();

  const isDesktop = useResponsive('up', 'lg');

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  if (!authenticatedUser) {
    return null;
  }

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' }
      }}
    >
      <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>
        <Logo />
      </Box>
      <Box sx={{ mb: 5, mx: 2.5 }}>
        <Link
          underline="none"
          component={ RouterLink }
          to="#"
        >
          <AccountStyle>
            <Avatar
              src="/static/mock-images/avatars/avatar_4.jpg"
              alt="photoURL"
            />
            <Box sx={{ ml: 2 }}>
              <Typography
                variant="subtitle2"
                sx={{ color: 'text.primary' }}
              >
                { authenticatedUser.fullName }
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: 'text.secondary' }}
              >
                Administrator
              </Typography>
            </Box>
          </AccountStyle>
        </Link>
      </Box>
      <NavSection navConfig={ SIDEBAR_CONFIG } />
    </Scrollbar>
  );

  return (
    <RootStyle>
      {
        !isDesktop && (
          <Drawer
            open={ isOpenSidebar }
            onClose={ onCloseSidebar }
            PaperProps={{
              sx: { width: DRAWER_WIDTH }
            }}
          >
            { renderContent }
          </Drawer>
        )
      }
      {
        isDesktop && (
          <Drawer
            open
            variant="persistent"
            PaperProps={{
              sx: {
                width: DRAWER_WIDTH,
                bgcolor: 'background.paper',
                borderRightStyle: 'dashed'
              }
            }}
          >
            { renderContent }
          </Drawer>
        )
      }
    </RootStyle>
  );
}

// Package modules
import { Link as RouterLink } from 'react-navi';
import { styled } from '@mui/material/styles';
import {
  Card,
  Link,
  Stack,
  Container,
  Typography
} from '@mui/material';

// Local modules
import Page from '../components/Page';
import AuthLayout from '../layouts/AuthLayout';
import { LoginForm } from '../sections/authentication/login';

// Styled components
const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// Exports
export default function Login() {
  return (
    <RootStyle title="Login">
      <AuthLayout>
        Don’t have an account? &nbsp;
        <Link
          underline="none"
          variant="subtitle2"
          component={ RouterLink }
          to="/register"
        >
          Get started
        </Link>
      </AuthLayout>
      <SectionStyle sx={{ display: { xs: 'none', md: 'flex' } }}>
        <Typography
          variant="h3"
          sx={{ px: 5, mt: 10, mb: 5 }}
        >
          Hi, Welcome Back
        </Typography>
        <img src="/static/illustrations/illustration_login.png" alt="login" />
      </SectionStyle>
      <Container maxWidth="sm">
        <ContentStyle>
          <Stack sx={{ mb: 5 }}>
            <Typography
              variant="h4"
              gutterBottom
            >
              Sign in to Giter
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              Enter your details below.
            </Typography>
          </Stack>
          {/*<AuthSocial />*/}
          <LoginForm />
          <Typography
            variant="body2"
            align="center"
            sx={{
              mt: 3,
              display: { sm: 'none' }
            }}
          >
            Don’t have an account?&nbsp;
            <Link
              variant="subtitle2"
              component={ RouterLink }
              to="register"
              underline="hover"
            >
              Get started
            </Link>
          </Typography>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}

// Package modules
import { useRef, useState } from 'react';
import { alpha } from '@mui/material/styles';
import {
  Box,
  Button,
  Avatar,
  Divider,
  Typography,
  IconButton
} from '@mui/material';

// Local modules
import MenuPopover from '../../components/MenuPopover';
import { useAuthService } from '../../lib/state';

// Exports
export default function AccountPopover() {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { authenticatedUser, logout } = useAuthService();

  // console.log("👉 authenticatedUser <>>>>>", authenticatedUser, "👈")
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    await logout();
    window.location.reload();
  };

  return (
    <>
      <IconButton
        ref={ anchorRef }
        onClick={ handleOpen }
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: '\'\'',
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
            }
          })
        }}
      >
        <Avatar src="/static/mock-images/avatars/avatar_4.jpg" alt="photoURL"/>
      </IconButton>
      {
        authenticatedUser && (
          <MenuPopover
            open={ open }
            onClose={ handleClose }
            anchorEl={ anchorRef.current }
            sx={{ width: 220 }}
          >
            <Box sx={{ my: 1.5, px: 2.5 }}>
              <Typography variant="subtitle1" noWrap>
                { authenticatedUser.fullName }
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: 'text.secondary' }}
                noWrap
              >
                { authenticatedUser.email }
              </Typography>
            </Box>
            <Divider sx={{ my: 1 }}/>
            <Box sx={{ p: 2, pt: 1.5 }}>
              <Button
                onClick={ handleLogout }
                color="inherit"
                variant="outlined"
                fullWidth
              >
                Logout
              </Button>
            </Box>
          </MenuPopover>
        )
      }
    </>
  );
}

import React from 'react';
import { Container, Grid, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Page from '../components/Page';
import { useContact } from '../lib/state';


const StyledForm = styled('div')`
    background-color: white;
    border: 1px dashed ${({ theme }) => theme.palette.grey['50032']};
    border-radius: 8px;
    padding: 24px;
    width: 93%;
`;

function ContactId(contactId) {
  const { data } = useContact(contactId);

  return (
    <Page title="Dashboard: Contact Page">
      <Container>
        <Typography variant="h4" component="div">
          Name - {data?.name} {data?.last_name}
          <Typography variant="subtitle2" component="div">
            contactId: {data?.id}
            <br />
            Created Date - {data?.date?.split('T')[0]}
          </Typography>
        </Typography>
        <br />
        <br />
        <Grid container spacing={5}>
          <Grid item xs={11} md={5}>
            <TextField
              type="text"
              fullWidth
              label="Country"
              defaultValue={data.country}
              InputProps={{
                readOnly: true
              }}
            />
          </Grid>
          <Grid item xs={11} md={5}>
            <TextField
              label="City"
              fullWidth
              defaultValue={data.city}
              InputProps={{
                readOnly: true
              }}
            />
          </Grid>
          <Grid item xs={11} md={5}>
            <TextField
              label="Phone"
              fullWidth
              defaultValue={data.phone}
              InputProps={{
                readOnly: true
              }}
            />
          </Grid>
          <Grid item xs={11} md={5}>
            <TextField
              label="Email"
              fullWidth
              defaultValue={data.email}
              InputProps={{
                readOnly: true
              }}
            />
          </Grid>
        </Grid>
        <br />
        <br />
        <StyledForm>
          <Grid item xs={12} md={2}>
            <b>Subject</b> : {data?.subject}
          </Grid>
          <br />

          <Grid container>
            <Grid item xs={12} md={8}>
              <TextField
                fullWidth
                label="Message"
                value={data?.message}
                multiline
                rows={8}
                InputProps={{
                  readOnly: true
                }}
              />
            </Grid>
          </Grid>
        </StyledForm>
      </Container>
    </Page>
  );
}

export default ContactId;
// Exports.
export const ENTITY_NOT_FOUND = 'EntityNotFound';
export const INVALID_CREDENTIALS = 'InvalidCredentials';
export const USER_ALREADY_EXISTS = 'UserAlreadyExists';
export const USER_ALREADY_INVITED = 'UserAlreadyInvited';
export const VALIDATION_ERRORS = 'ValidationErrors';
export const TOKEN_EXPIRED = 'TokenExpired';
export const INSUFFICIENT_PERMISSIONS = 'InsufficientPermissions';

export default class ApiError extends Error {
  constructor(sourceError) {
    super(sourceError.detail);
    this.name = 'ApiError';
    this.displayMessage = sourceError.detail || this.message;
    this.sourceError = sourceError.detail;
    this.detail = sourceError.detail;
  }
}

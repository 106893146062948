// Package modules
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useEffect, useState } from 'react';

/**
 * This function finds the matching text based on what the user has typed and wraps it in a <mark> tag.
 * @param input
 * @param option
 * @return {*}
 */
export const getOptionWithHighlights = (input, option) => {
  const regex = new RegExp(input, 'gi');
  const result = option.replace(regex, '<mark>$&</mark>');

  return input.length > 0 ? result : option;
};

/**
 * @param query
 * @param key
 * @param start
 * @param end
 * @return {null|boolean}
 */
export const useResponsive = (query, key, start, end) => {
  const theme = useTheme();

  const mediaUp = useMediaQuery(theme.breakpoints.up(key));

  const mediaDown = useMediaQuery(theme.breakpoints.down(key));

  const mediaBetween = useMediaQuery(theme.breakpoints.between(start, end));

  const mediaOnly = useMediaQuery(theme.breakpoints.only(key));

  if (query === 'up') {
    return mediaUp;
  }

  if (query === 'down') {
    return mediaDown;
  }

  if (query === 'between') {
    return mediaBetween;
  }

  if (query === 'only') {
    return mediaOnly;
  }
  return null;
};

export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay || 500)

    return () => {
      clearTimeout(timer)
    }
  }, [value, delay])

  return debouncedValue;
};

export const convertFileToBase64 = (file, callback) => {
  // Encode the file using the FileReader API
  const reader = new FileReader();
  reader.onloadend = () => {
    // Use a regex to remove data url part
    const base64String = reader.result
      // .replace('data:', '')
      // .replace(/^.+,/, '');

    callback(base64String);
  };
  reader.readAsDataURL(file);
};

export const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}


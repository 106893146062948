import { filter } from 'lodash';
import React, { useState } from 'react';
import { Link as RouterLink, useNavigation } from 'react-navi';

// material
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination, Button, IconButton, Tab, Tabs
} from '@mui/material';

// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import { descendingComparator } from '../lib/utils';
import Iconify from '../components/Iconify';
import { useMessages } from '../lib/state';
import MailListHead from '../sections/@dashboard/MailListHead';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'country', label: 'Country', alignRight: false },
  { id: 'rang', label: 'Rang', alignRight: false },
  { id: 'created_date', label: 'Created Date', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false }
];

///----------------------------------------------
function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.user_name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis?.map((el) => el[0]);
}

const MailLetters = () => {
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [orderBy, setOrderBy] = useState('name');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [selected, setSelected] = useState([]);

  const { data } = useMessages();

  const readyCampaigns = data.ready;
  const closeCampaigns = data.close;
  const doneCampaigns = data.done;
  const [currentTabIndex, setCurrentTabIndex] = useState('1');
  const [messagesForSort, setMessagesForSort] = useState(readyCampaigns || '');

  // console.log(messagesForSort, readyCampaigns, closeCampaigns);
  const handleTabChange = (e, tabIndex) => {
    setCurrentTabIndex(tabIndex);
  };
  const readyTab = () => {
    setMessagesForSort(readyCampaigns);
  };
  const closeTab = () => {
    setMessagesForSort(closeCampaigns);
  };

  const doneTab = () => {
    setMessagesForSort(doneCampaigns);
  };

  const { navigate } = useNavigation();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - messagesForSort.length) : 0;

  const filteredMails = applySortFilter(messagesForSort, getComparator(order, orderBy));

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = messagesForSort?.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  return (
    <Page title="MailLetters">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Mail letters
          </Typography>
          <Button
            color="inherit"
            variant="contained"
            component={RouterLink}
            to="#"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={() => navigate(`/dashboard/mailletters/add`)}
          >
            Create New
          </Button>
        </Stack>

        <Tabs value={currentTabIndex} onChange={handleTabChange}>
          <Tab label="Ready" onClick={readyTab} value="1" />
          <Tab label="Done" onClick={doneTab} value="2" />
          <Tab label="Close" onClick={closeTab} value="3" />
        </Tabs>
        <br />
        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <MailListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={messagesForSort?.length}
                  onRequestSort={handleRequestSort}
                  numSelected={selected?.length}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredMails?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { _id, name, rang, country, created_date, status } = row;

                    return <TableRow
                      hover
                      key={_id}
                      onClick={() => navigate(`/dashboard/mailletters/${row._id}`)}
                    >
                      <TableCell align="left">{name}</TableCell>
                      <TableCell align="left">{country}</TableCell>
                      <TableCell align="left">{rang}</TableCell>
                      <TableCell align="left">
                        {created_date !== null && created_date?.split('T')[0]}
                      </TableCell>
                      <TableCell align="left">{status}</TableCell>
                      <TableCell align="left">
                        <IconButton onClick={() => navigate(`/dashboard/mailletters/${row._id}`)}>
                          <Iconify icon="mdi:edit" width={22} height={22} color="green" />
                        </IconButton>
                      </TableCell>
                    </TableRow>;
                  })}
                  {emptyRows > 0 && <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={messagesForSort?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
};

export default MailLetters;
// Package modules
import { useEffect } from 'react';
import { useCurrentRoute } from 'react-navi';

// Exports
export default function ScrollToTop() {
  const { url: { pathname } } = useCurrentRoute();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

import React, { useState, useEffect } from 'react';
import { useNavigation } from 'react-navi';
import { filter } from 'lodash';
import {
  Card, Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow, Typography
} from '@mui/material';
import Scrollbar from '../components/Scrollbar';
import { useContacts } from '../lib/state';
import MailListHead from '../sections/@dashboard/MailListHead';

function Contacts() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('date');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [sortedData, setSortedData] = useState([]);
  const { data } = useContacts();
  const { navigate } = useNavigation();

  const columns_head = [
    { id: 'name', label: 'Name', alignRight: false },
    { id: 'last_name', label: 'Last Name', alignRight: false },
    { id: 'country', label: 'Country', alignRight: false },
    { id: 'city', label: 'City', alignRight: false },
    { id: 'email', label: 'Email', alignRight: false },
    { id: 'phone', label: 'Phone', alignRight: false },
    { id: 'date', label: 'Date', alignRight: false }
  ];

  function descendingComparator(a, b, orderBy) {
    if (orderBy === 'date') {
      return new Date(a[orderBy]) - new Date(b[orderBy]);
    }
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    if (query) {
      return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
  }

  useEffect(() => {
    const sortedInitialData = [...data].sort((a, b) => descendingComparator(a, b, 'date'));
    setSortedData(sortedInitialData);
  }, [data]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    const sortedData = applySortFilter(sortedData, getComparator(order, property));
    setSortedData(sortedData);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - sortedData.length) : 0;

  const filteredContacts = applySortFilter(sortedData, getComparator(order, orderBy));

  if (data.length === 0) {
    return 'Ещё нет запросов.';
  }

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Contacts
      </Typography>
      <br />
      <br />
      <Card>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <MailListHead
                order={order}
                orderBy={orderBy}
                headLabel={columns_head}
                rowCount={sortedData.length}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {filteredContacts?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                  const { id, name, last_name, country, city, date, email, phone } = row;

                  return (
                    <TableRow
                      hover
                      key={id}
                      onClick={() => navigate(`/dashboard/contacts/${row.id}`)}
                    >
                      <TableCell align="left">{name}</TableCell>
                      <TableCell align="left">{last_name}</TableCell>
                      <TableCell align="left">{country}</TableCell>
                      <TableCell align="left">{city}</TableCell>
                      <TableCell align="left">{email}</TableCell>
                      <TableCell align="left">{phone}</TableCell>
                      <TableCell align="left">{date !== null && date?.split('T')[0]}</TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={7} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={sortedData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
    </Container>
  );
}

export default Contacts;

// Package modules
import { styled } from '@mui/material';
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  FilePond,
  registerPlugin
} from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

// Constants
const DEFAULT_SETTINGS = {
  FILES: [],
  FIELD_NAME: 'files',
  LABEL: 'Drag & Drop your files or <span class="filepond--label-action">Browse</span>',
  MAX_FILES: 5,
};

// Styled components
const WrapperFilePond = styled('div', {
  shouldForwardProp(propName) {
    return !['showBackground'].includes(propName);
  }
})`
  .filepond--item {
    width: 200px;
  }
  
  .filepond--credits {
    display: none;
  }
  
  .filepond--drip,
  .filepond--panel-root {
    background-color: ${({ theme, showBackground }) => showBackground ? theme.palette.primary.lighter : 'transparent' };
    background: ${({ theme, showBackground }) => showBackground ? theme.palette.primary.lighter : 'transparent' };
  }
  
  .filepond--list {
    left: 0;
    right: 0;
  }
`;

// Exports
const FileLoader = forwardRef(({
  fieldName,
  label,
  maxFiles,
  files,
  setFiles,
  wrapperProps,
  ...remainingProps
}, ref) => {
  return (
    <WrapperFilePond
      showBackground={ files.length === 0 }
      { ...wrapperProps }
    >
      <FilePond
        ref={ ref }
        name={ fieldName }// sets the file input name, it's filepond by default
        labelIdle={ label }
        files={ files }
        onupdatefiles={ setFiles }
        maxFiles={ maxFiles }
        allowMultiple
        { ...remainingProps }
      />
    </WrapperFilePond>
  )
});

FileLoader.defaultProps = {
  fieldName: DEFAULT_SETTINGS.FIELD_NAME,
  label: DEFAULT_SETTINGS.LABEL,
  maxFiles: DEFAULT_SETTINGS.MAX_FILES,
  files: DEFAULT_SETTINGS.FILES,
};

FileLoader.propTypes = {
  fieldName: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  maxFiles: PropTypes.number,
  files: PropTypes.array,
  setFiles: PropTypes.func,
  wrapperProps: PropTypes.object
};

export default FileLoader;

export const getCountry =
  [
    {
      '_id': '001',
      'name': 'Молдова',
      'short': 'MDL'
    },
    {
      '_id': '002',
      'name': 'Украина',
      'short': 'UAH'
    },
    {
      '_id': '003',
      'name': 'Россия',
      'short': 'RUB'
    },
    {
      '_id': '004',
      'name': 'Беларусь',
      'short': 'BYN'
    },
    {
      '_id': '005',
      'name': 'Казахстан',
      'short': 'KZT'
    },
    {
      '_id': '006',
      'name': 'Узбекистан',
      'short': 'UZS'
    },
    {
      '_id': '007',
      'name': 'Таджикистан',
      'short': 'TJS'
    },
    {
      '_id': '008',
      'name': 'Румыния',
      'short': 'RON'
    },
    {
      '_id': '009',
      'name': 'Греция',
      'short': 'EUR'
    },
    {
      '_id': '010',
      'name': 'Испания',
      'short': 'EUR'
    },
    {
      '_id': '011',
      'name': 'Словения',
      'short': 'SIT'
    },
    {
      '_id': '012',
      'name': 'Турция',
      'short': 'TRY'
    },
    {
      '_id': '013',
      'name': 'Австралия',
      'short': 'AUD'
    },
    {
      '_id': '014',
      'name': 'Italy',
      'short': 'EUR'
    },
    {
      '_id': '015',
      'name': 'Great Britain',
      'short': 'GBR'
    },
    {
      '_id': '016',
      'name': 'Кыргызстан',
      'short': 'KGS'
    },
    {
      '_id': '017',
      'name': 'Грузия',
      'short': 'GEL'
    },
    {
      '_id': '018',
      'name': 'Israel',
      'short': 'NIS'
    },
    {
      '_id': '019',
      'name': 'Germany',
      'short': 'EUR'
    },
    {
      '_id': '020',
      'name': 'Nederland',
      'short': 'EUR'
    },
    {
      '_id': '021',
      'name': 'Ireland',
      'short': 'EUR'
    },
    {
      '_id': '022',
      'name': 'England',
      'short': 'GBR'
    }
    ,{
    '_id': '023',
    'name': 'Франция',
    'short': 'EUR'
  },
    {
      '_id': '024',
      'name': 'Португалия',
      'short': 'EUR'
    },
    {
      '_id': '025',
      'name': 'Czechia',
      'short': 'CZK'
    },
    {
      '_id': '026',
      'name': 'Austria',
      'short': 'EUR'
    },
    {
      '_id': '027',
      'name': 'Poland',
      'short': 'PLZ'
    },
    {
      '_id': '028',
      'name': 'Switzerland',
      'short': 'CHF'
    }
  ];
// Package modules
import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

// Local modules

// Styled components

// Exports
export const ProgressCircular = () => {
  return (
    <Backdrop
      sx={{
        color: '#fff',
        zIndex: (theme) => theme.zIndex.drawer + 1,
        position: 'absolute'
      }}
      open
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

// Package modules
import React, {useState} from 'react';
import {useNavigation } from 'react-navi';
import {
  Stack,
  useTheme,
  Container,
  Typography, Box, Tab
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

// Local modules
import {TabContext, TabList, TabPanel} from "@mui/lab";
import Page from '../components/Page';
import { usePartners } from '../lib/state';

// Constants
const columns = [
  { field: 'id', headerName: 'ID', flex: 1, minWidth: 70 },
  { field: 'full_name', headerName: 'Full Name', flex: 1, minWidth: 160 },
  { field: 'phone', headerName: 'Phone', flex: 1, minWidth: 130 },
  { field: 'email', headerName: 'Email', flex: 1, minWidth: 160 },
  { field: 'country', headerName: 'Country', flex: 1, minWidth: 130 },
  { field: 'city', headerName: 'City', flex: 1, minWidth: 130 },
  { field: 'referral', headerName: 'Referral', flex: 1, minWidth: 130 },
  // { field: 'status', headerName: 'Status', flex: 1, minWidth: 130 },
];



const RequestsTableNew = () => {
  const { data } = usePartners();
  const { navigate } = useNavigation();

  if (data.length === 0) {
    return 'Ещё нет запросов.';
  }

  return (
    <div style={{ height: 632, width: '100%' }}>
        <DataGrid
            rows={data.new}
            onRowClick={(rows) => navigate(`/dashboard/partner-requests/${rows.id}`)}
            columns={columns}
            pagination='true'
            rowsPerPageOptions={[5, 25, 100]}
            paginationMode='client'
            paginationModel={{
                pageSize: 5,
                page: 0,
            }}
        />
    </div>
  );
};

const RequestsTableWarehouseAssigned = () => {
  const { data } = usePartners();
  const { navigate } = useNavigation();

  if (data.length === 0) {
    return 'Ещё нет запросов.';
  }

  return (
      <div style={{ height: 632, width: '100%' }}>
          <DataGrid
              rows={data.warehouseAssigned}
              onRowClick={(rows) => navigate(`/dashboard/partner-requests/${rows.id}`)}
              columns={columns}
              pagination='true'
              rowsPerPageOptions={[5, 25, 100]}
              paginationMode='client'
              paginationModel={{
                  pageSize: 5,
                  page: 0,
              }}
          />
      </div>
  );
};

const RequestsTableRejected = () => {
  const theme = useTheme();
  const { data } = usePartners();
  const { navigate } = useNavigation();

  if (data.length === 0) {
    return 'Ещё нет запросов.';
  }

  return (
      <div style={{ height: 632, width: '100%' }}>
        <DataGrid
            rows={ data.rejected }
            onRowClick={(rows) => navigate(`/dashboard/partner-requests/${rows.id}`)}
            columns={columns}
            pagination='true'
            rowsPerPageOptions={[5, 25, 100]}
            paginationMode='client'
            paginationModel={{
                pageSize: 5,
                page: 0,
            }}
            sx={ {
              borderColor: theme.palette.grey['50032']
            }}
        />
      </div>
  );
};

const RequestsTableSendedToOneC = () => {
  const theme = useTheme();
  const { data } = usePartners();
  const { navigate } = useNavigation();

  if (data.length === 0) {
    return 'Ещё нет запросов.';
  }

  return (
      <div style={{ height: 632, width: '100%' }}>
        <DataGrid
            rows={ data.sendedToOneC }
            onRowClick={(rows) => navigate(`/dashboard/partner-requests/${rows.id}`)}
            columns={columns}
            pagination='true'
            rowsPerPageOptions={[5, 25, 100]}
            paginationMode='client'
            paginationModel={{
                pageSize: 5,
                page: 0,
            }}
            sx={ {
              borderColor: theme.palette.grey['50032']
            }}
        />
      </div>
  );
};
// Exports
function PartnerRequests() {
  const [value, setValue] = useState("0");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Page title="Dashboard: Partner Requests">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={ 5 }
        >
          <Typography
            variant="h4"
            component="div"
          >
            Partner Requests
          </Typography>
        </Stack>
        <Box sx={{ width: "100%"}}>
          <TabContext value={value}>
            <Box>
              <TabList onChange={handleChange}>
                <Tab label="new" value="0" />
                <Tab label="warehouseAssigned" value="1" />
                <Tab label="rejected" value="2" />
                <Tab label="sendedToOneC" value="3" />
              </TabList>
            </Box>
            <TabPanel value="0">
              <RequestsTableNew />
            </TabPanel>
            <TabPanel value="1">
                <RequestsTableWarehouseAssigned />
            </TabPanel>
            <TabPanel value="2">
              <RequestsTableRejected />
            </TabPanel>
            <TabPanel value="3">
              <RequestsTableSendedToOneC />
            </TabPanel>
          </TabContext>
        </Box>
        {/*<RequestsTable />*/}
      </Container>
    </Page>
  );
}

export default PartnerRequests;

// Local modules
import ApiError from '../components/error/ApiError';
import { BLOG_TYPES, HOST } from './constants';
import { AuthService } from './authService';

export const api = {};

api.auth = {
  async login (username, password) {
    const formData = new FormData();

    formData.append('username', username);
    formData.append('password', password);

    const res = await fetch(`${HOST}/login/`, {
      method: 'POST',
      body: formData
    });

    const body = await res.json();

    if (res.status !== 200) {
      throw new ApiError(body);
    }

    return body;
  },
  async logout () {
    const res = await fetch(`${HOST}/logout/`, {
      method: 'GET',
      headers: AuthService.getAuthServiceInstance().withAuthHeader()
    });

    const body = await res.json();

    if (res.status !== 200) {
      throw new ApiError(body);
    }

    return body;
  },
  async me (token) {
    const headers = token
      ? { Authorization: `Bearer ${token}` }
      : AuthService.getAuthServiceInstance().withAuthHeader();

    const user = await fetch(`${HOST}/users/me/`, { headers });

    const body = await user.json();

    if (user.status !== 200) {
      throw new ApiError(body);
    }

    return body;
  },
};

api.blog = {
  async getPost (postId) {
    const res = await fetch(`${HOST}/articles/?id=${postId}`, {
      method: 'GET',
    });

    const body = await res.json();

    if (res.status !== 200) {
      throw new ApiError(body);
    }

    return body[0];
  },
  async createPost (post) {
    const res = await fetch(`${HOST}/articles/`, {
      method: 'POST',
      headers: AuthService.getAuthServiceInstance().withAuthHeader({
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify(post)
    });

    const body = await res.json();

    if (res.status !== 200) {
      throw new ApiError(body);
    }

    return body;
  },
  async updatePost (postId, postProps) {
    const res = await fetch(`${HOST}/articles/`, {
      method: 'PUT',
      headers: AuthService.getAuthServiceInstance().withAuthHeader({ 'Content-Type': 'application/json' }),
      body: JSON.stringify({ ...postProps, id: postId })
    });

    const body = await res.json();

    if (res.status !== 200) {
      throw new ApiError(body);
    }

    return body;
  },
  async deletePost (postId) {
    const res = await fetch(`${HOST}/articles/?id=${postId}`, {
      method: 'DELETE',
      headers: AuthService.getAuthServiceInstance().withAuthHeader(),
    });

    const body = await res.json();

    if (res.status !== 200) {
      throw new ApiError(body);
    }

    return body;
  },
  async getPosts (blogType) {
    const isNewsType = blogType === BLOG_TYPES.NEWS;
    const res = await fetch(`${HOST}/articles/?is_news=${isNewsType}`, {
      method: 'GET'
    });

    const body = await res.json();

    if (res.status !== 200) {
      throw new ApiError(body);
    }

    return body;
  }
};

api.products = {
  async getProduct (productType, productId) {
    const res = await fetch(`${HOST}/catalog/${productType}/?id=${productId}`, {
      method: 'GET',
      // headers: AuthService.getAuthServiceInstance().withAuthHeader(),
    });

    const body = await res.json();

    if (res.status !== 200) {
      throw new ApiError(body);
    }

    return body[0];
  },
  async createProduct (productType, product) {
    const res = await fetch(`${HOST}/catalog/${productType}/`, {
      method: 'POST',
      headers: AuthService.getAuthServiceInstance().withAuthHeader({ 'Content-Type': 'application/json' }),
      body: JSON.stringify(product)
    });

    const body = await res.json();

    if (res.status !== 200) {
      throw new ApiError(body);
    }

    return body;
  },
  async updateProduct (productType, productId, product) {
    const res = await fetch(`${HOST}/catalog/${productType}/`, {
      method: 'PUT',
      headers: AuthService.getAuthServiceInstance().withAuthHeader({ 'Content-Type': 'application/json' }),
      body: JSON.stringify({ ...product, id: productId })
    });

    const body = await res.json();

    if (res.status !== 200) {
      throw new ApiError(body);
    }

    return body;
  },
  async deleteProduct (categoryId, productId) {
    const res = await fetch(`${HOST}/catalog/${categoryId}/?id=${productId}`, {
      method: 'DELETE',
      headers: AuthService.getAuthServiceInstance().withAuthHeader(),
    });

    const body = await res.json();

    if (res.status !== 200) {
      throw new ApiError(body);
    }

    return body;
  },
  async getProducts (categoryId) {
    const res = await fetch(`${HOST}/catalog/${categoryId}/`, {
      method: 'GET'
    });

    const body = await res.json();

    if (res.status !== 200) {
      throw new ApiError(body);
    }

    return body;
  }
};

api.partner = {
  async getList () {
    const res = await fetch(`${HOST}/users/partner-registration/`, {
      method: 'GET',
      headers: AuthService.getAuthServiceInstance().withAuthHeader(),
    });

    const body = await res.json();

    if (res.status !== 200) {
      throw new ApiError(body);
    }

    return body.data;
  },
};

api.admin = {
  async getList () {
    const res = await fetch(`${HOST}/admin/maillatters/all/`, {
      method: 'GET',
      headers: AuthService.getAuthServiceInstance().withAuthHeader(),
    });

    const body = await res.json();

    if (res.status !== 200) {
      throw new ApiError(body);
    }

    return body;
  },
  async getListById(maillettersId)
  {
    const res = await fetch(`${HOST}/admin/maillatters/${maillettersId.maillettersId}`, {
      method: 'GET',
      headers: AuthService.getAuthServiceInstance().withAuthHeader({
        'Content-Type': 'application/json'
      })
    });
    const body = await res.json();

    if (res.status !== 200) {
      throw new ApiError(body);
    }

    return body;
  },
  async getListContacts()
  {
    const res = await fetch(`${HOST}/admin/contact-us/`, {
      method: 'GET',
      headers: AuthService.getAuthServiceInstance().withAuthHeader({
        'Content-Type': 'application/json'
      })
    });
    const body = await res.json();

    if (res.status !== 200) {
      throw new ApiError(body);
    }

    return body;
  },
  async getListContactById(contactId)
  {
    console.log('daa', contactId);
    const res = await fetch(`${HOST}/admin/contact-us/${contactId.contactId}`, {
      method: 'GET',
      headers: AuthService.getAuthServiceInstance().withAuthHeader({
        'Content-Type': 'application/json'
      })
    });
    const body = await res.json();

    if (res.status !== 200) {
      throw new ApiError(body);
    }

    return body;
  },

};

api.theme = {
  async getThemeSettings () {
    const res = await fetch(`${HOST}/admin/home-page/`, {
      method: 'GET',
    });

    const body = await res.json();

    if (res.status !== 200) {
      throw new ApiError(body);
    }

    return body;
  },
  async updateThemeSettings (themeConfig) {
    const res = await fetch(`${HOST}/admin/home-page/`, {
      method: 'POST',
      headers: AuthService.getAuthServiceInstance().withAuthHeader({ 'Content-Type': 'application/json' }),
      body: JSON.stringify(themeConfig)
    });

    const body = await res.json();

    if (res.status !== 200) {
      throw new ApiError(body);
    }

    return body;
  }
};

api.warehouseList = {
  async getWarehouseList() {
    const res = await fetch(`${HOST}/admin/get-warehouse-list`, {
      method: 'GET',
      headers: AuthService.getAuthServiceInstance().withAuthHeader(),
    });
    const body = await res.json();

    if (res.status !== 200) {
      throw new ApiError(body);
    }


    return body;

  },
};

export function postWarehouseNr(nrWareHouse, newPartnerId) {
  return fetch(`${HOST}/users/partner-registration/add-warehouse?id=${newPartnerId}&wh_id=${nrWareHouse}`, {
    method: 'POST',
    body: null,
    headers: AuthService.getAuthServiceInstance().withAuthHeader({
      'Content-Type': 'application/json'
    })
  });
}

export function rejectPartner(newPartnerId) {
  return fetch(`${HOST}/users/partner-registration/reject?id=${newPartnerId}`, {
    method: 'POST',
    body: null,
    headers: AuthService.getAuthServiceInstance().withAuthHeader({
      'Content-Type': 'application/json'
    })
  });
}

export function addComment(newPartnerId, values)
{
  console.log('values', values, values.text, newPartnerId)
  return fetch(`${HOST}/users/partner-registration/add-comment?id=${newPartnerId}&comment=${values.text}`, {
    method: 'POST',
    body: null,
    headers: AuthService.getAuthServiceInstance().withAuthHeader({
      'Content-Type': 'application/json'
    })
  });
}

export function getMailLettersById(maillettersId)
{
  return fetch(`${HOST}/admin/maillatters/${maillettersId.maillettersId}`, {
    method: 'GET',
    headers: AuthService.getAuthServiceInstance().withAuthHeader({
      'Content-Type': 'application/json'
    })
  });
}

export function editMaillettersById ({maillettersId, payload} ) {
  return  fetch(`${HOST}/admin/maillatters/${maillettersId.maillettersId}/edit`, {
    method: 'POST',
    headers: AuthService.getAuthServiceInstance().withAuthHeader({
      'Content-Type': 'application/json' }),
    body: JSON.stringify({ ...payload, id: maillettersId.maillettersId })
  });
}


export function newMailletters ({ payload} ) {
  console.log(payload, 'payload new')
  return  fetch(`${HOST}/admin/mailletters/new`, {
    method: 'POST',
    headers: AuthService.getAuthServiceInstance().withAuthHeader({
      'Content-Type': 'application/json',
      'accept': 'application/json'
    }),
    body: JSON.stringify({ ...payload })
  });
}

export function deleteMailLetter(maillettersId) {
  console.log(maillettersId, 'maillettersId');
  return fetch(`${HOST}/admin/maillatters/${maillettersId.maillettersId}/close`, {
    method: 'POST',
    body: null,
    headers: AuthService.getAuthServiceInstance().withAuthHeader({
      'Content-Type': 'application/json'
    })
  });
}
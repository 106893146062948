// Package modules
import { useState } from 'react';
import {
  useNavigation,
  Link as RouterLink
} from 'react-navi';
import {
  Grid,
  Stack,
  Button,
  Container,
  Typography
} from '@mui/material';

// Local modules
import Page from '../components/Page';
import Iconify from '../components/Iconify';
import { ROOT_BLOG_CATEGORIES } from '../lib/constants';
import { useBlogPosts } from '../lib/state';
import {
  BlogPostCard,
  BlogPostsSearch
} from '../sections/@dashboard/blog';

// Exports
export default function Blog({ blogType }) {
  const { data: postList } = useBlogPosts(blogType);

  const [posts, setPosts] = useState(postList);
  const { navigate } = useNavigation();

  const filterPosts = (event) => {
    const searchText = event.target.value;
    if (searchText.trim().length === 0) {
      setPosts(postList);
      return;
    }

    const filteredPosts = postList.filter(post => post.title.trim().toLowerCase().includes(searchText.trim().toLowerCase()));
    setPosts(filteredPosts);
  };

  return (
    <Page title="Dashboard: Blog">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={ 5 }
        >
          <Typography
            variant="h4"
            gutterBottom
          >
            Blog > &nbsp;
            { ROOT_BLOG_CATEGORIES[blogType] }
          </Typography>
          <Button
            variant="contained"
            component={ RouterLink }
            to="#"
            startIcon={ <Iconify icon="eva:plus-fill" /> }
            onClick={ () => navigate(`/dashboard/blog/add/?blogType=${blogType}`) }
          >
            New Post
          </Button>
        </Stack>
        <Stack
          mb={ 5 }
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <BlogPostsSearch onChange={ filterPosts } />
        </Stack>
        <Grid
          container
          spacing={ 3 }
        >
          {
            posts.map((post, index) => (
              <BlogPostCard
                key={ post.id }
                post={ post }
                blogType={ blogType }
                index={ index }
              />
            ))
          }
        </Grid>
      </Container>
    </Page>
  );
}

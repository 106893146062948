// Package modules
import React, { useMemo, useState } from 'react';
import {LoadingButton, TabContext, TabList, TabPanel} from '@mui/lab';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import {
  Form,
  useFormik,
  FormikProvider
} from 'formik';
import {
  Box,
  alpha,
  Stack,
  styled,
  Container,
  TextField,
  Typography, Tab
} from '@mui/material';

// Local modules
import Page from '../components/Page';
import Iconify from '../components/Iconify';
import { Select } from '../components/Select';
import ShopProductCard from '../sections/@dashboard/products/ProductCard';
import { getOptionWithHighlights } from '../lib/utils';
import {
  useAllProduct,
  useThemeSettings
} from '../lib/state';

// Assets
import DummySrc from '../assets/dummy.jpg';

// Styled components
const FieldsContainer = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  width: 100%;
  
  @media (max-width: 720px) {
    grid-template-columns: 1fr;
  }
`;

const ProductsContainer = styled(Box)`
  display: flex;
  gap: 24px;
  width: 100%;
  overflow-x: auto;
  
  & > div {
    width: 270px;
    flex-shrink: 0;
  }
`;

const CustomSelectOption = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 6px 16px;
  
  &:hover {
    background-color: ${({ theme }) => alpha(theme.palette.primary.dark, 0.10)};
  }
`;

const TextMark = styled(Typography)`
  & mark {
    background-color: ${({ theme }) => alpha(theme.palette.primary.dark, 0.15)};
  }
`;

const transformProductsForSelect = (products) => products.map(product => ({
  ...product,
  value: product.id,
  label: product.name || product.brand_name,
}));

const SelectOption = ({
  data,
  innerProps,
  isSelected,
  selectProps
}) => (
  <CustomSelectOption { ...innerProps }>
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
      <img
        src={ data?.cover.cover ?? DummySrc }
        alt={ data.label }
        style={{ width: '32px', borderRadius: 4 }}
      />
      <TextMark
        dangerouslySetInnerHTML={{ __html: getOptionWithHighlights(selectProps.inputValue, data.label) }}
      />
    </Box>
    {
      isSelected ? (
        <Iconify
          icon="ic:baseline-minus"
          width={ 20 }
          height={ 20 }
          sx={{ flexShrink: 0 }}
        />
      ) : (
        <Iconify
          icon="ic:baseline-plus"
          width={ 20 }
          height={ 20 }
          sx={{ flexShrink: 0 }}
        />
      )
    }
  </CustomSelectOption>
);

SelectOption.propTypes = {
  data: PropTypes.object,
  innerProps: PropTypes.object,
  isSelected: PropTypes.bool,
  selectProps: PropTypes.object,
};

// Exports
function ThemeConfig () {
  const {
    data: { home_page: themeConfig },
    updateThemeSettings
  } = useThemeSettings();
  const { data } = useAllProduct();

  const allProducts = useMemo(() => transformProductsForSelect(data), [data]);
  const selectedNewProducts = useMemo(() => allProducts.filter(product => themeConfig.newProducts.includes(product.value)), [themeConfig.newProducts, allProducts]);
  const selectedRecommendedProducts = useMemo(() => allProducts.filter(product => themeConfig.recommendedProducts.includes(product.value)), [themeConfig.recommendedProducts, allProducts]);

  const [newProducts, setNewProducts] = useState(selectedNewProducts);
  const [recommendedProducts, setRecommendedProducts] = useState(selectedRecommendedProducts);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const ThemeSchema = Yup.object().shape({
    homepage: Yup.object({
      leftBannerText: Yup.string(),
      rightBannerText: Yup.string(),
      leftBannerUrl: Yup.string(),
      rightBannerUrl: Yup.string(),
      leftBannerImageSrc: Yup.string(),
      rightBannerImageSrc: Yup.string(),
      inUrl: Yup.string(),
      fbUrl: Yup.string(),
      ytUrl: Yup.string(),
      tgUrl: Yup.string()
    }),
  });

  const themeForm = useFormik({
    initialValues: {
      leftBannerText: themeConfig.leftBannerText.ru || '',
      rightBannerText: themeConfig.rightBannerText.ru || '',
      leftBannerUrl: themeConfig.leftBannerUrl || '',
      rightBannerUrl: themeConfig.rightBannerUrl || '',
      leftBannerImageSrc: themeConfig.leftBannerImageSrc || '',
      rightBannerImageSrc: themeConfig.rightBannerImageSrc || '',
      inUrl: themeConfig.inUrl || '',
      fbUrl: themeConfig.fbUrl || '',
      ytUrl: themeConfig.ytUrl || '',
      tgUrl: themeConfig.tgUrl || ''
    },
    validationSchema: ThemeSchema
  });
  // const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = postForm;

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    const payload = {
      ...themeForm.values,
      newProducts: newProducts.map(product => product.id),
      recommendedProducts: recommendedProducts.map(product => product.id)
    }

    console.log("👉 themeForm", payload, "👈")
    await updateThemeSettings(payload);

    setIsSubmitting(false);
  };
  const [value, setValue] = React.useState("1");
  const [valueRight, setValueRight] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeRight = (event, newValueRight) => {
    setValueRight(newValueRight);
  };


  return (
    <Page title="Dashboard: Theme Config">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={ 5 }
        >
          <Typography
            variant="h4"
            component="div"
          >
            Theme Config
          </Typography>
        </Stack>
        <FormikProvider value={ themeForm }>
          <Form
            autoComplete="off"
            onSubmit={ handleSubmit }
            noValidate
          >
            <Stack spacing={ 6 }>
              <Stack spacing={ 2 }>
                <Typography variant="subtitle1">
                  Banner
                </Typography>
                <FieldsContainer>
                  <Box sx={{ width: "100%"}}>
                    <TabContext value={value}>
                      <Box>
                        <TabList onChange={handleChange}>
                          <Tab label="leftBannerText_RO" value="1" />
                          <Tab label="leftBannerText_RU" value="2" />
                          <Tab label="leftBannerText_EN" value="3" />
                        </TabList>
                      </Box>
                      <TabPanel value="1"><TextField type="text" fullWidth label="Left banner text ro" defaultValue= {themeConfig.leftBannerText.ro || ''} /></TabPanel>
                      <TabPanel value="2"><TextField type="text" fullWidth label="Left banner text ru" defaultValue= {themeConfig.leftBannerText.ru || ''} /></TabPanel>
                      <TabPanel value="3"><TextField type="text" fullWidth label="Left banner text en" defaultValue= {themeConfig.leftBannerText.en || ''} /></TabPanel>
                    </TabContext>
                  </Box>
                  <Box sx={{ width: "100%"}}>
                    <TabContext value={valueRight}>
                      <Box>
                        <TabList onChange={handleChangeRight}>
                          <Tab label="rightBannerText_RO" value="1" />
                          <Tab label="rightBannerText_RU" value="2" />
                          <Tab label="rightBannerText_EN" value="3" />
                        </TabList>
                      </Box>
                      <TabPanel value="1"><TextField type="text" fullWidth label="Right banner text ro"  defaultValue= {themeConfig.rightBannerText.ro || ''} /></TabPanel>
                      <TabPanel value="2"><TextField type="text" fullWidth label="Right banner text ru"  defaultValue= {themeConfig.rightBannerText.ru || ''} /></TabPanel>
                      <TabPanel value="3"><TextField type="text" fullWidth label="Right banner text en"  defaultValue= {themeConfig.rightBannerText.en || ''} /></TabPanel>
                    </TabContext>
                  </Box>
                  <TextField
                    fullWidth
                    type="text"
                    label="Left banner url"
                    { ...themeForm.getFieldProps('leftBannerUrl') }
                  />
                  <TextField
                    fullWidth
                    type="text"
                    label="Right banner url"
                    { ...themeForm.getFieldProps('rightBannerUrl') }
                  />
                  <TextField
                    fullWidth
                    type="text"
                    label="Left banner image link"
                    { ...themeForm.getFieldProps('leftBannerImageSrc') }
                  />
                  <TextField
                    fullWidth
                    type="text"
                    label="Right banner image link"
                    { ...themeForm.getFieldProps('rightBannerImageSrc') }
                  />
                </FieldsContainer>
              </Stack>
              <Stack spacing={ 2 }>
                <Typography variant="subtitle1">
                  Socials
                </Typography>
                <FieldsContainer>
                  <TextField
                    fullWidth
                    type="text"
                    label="Instagram url"
                    { ...themeForm.getFieldProps('inUrl') }
                  />
                  <TextField
                    fullWidth
                    type="text"
                    label="Facebook url"
                    { ...themeForm.getFieldProps('fbUrl') }
                  />
                  <TextField
                    fullWidth
                    type="text"
                    label="YouTube url"
                    { ...themeForm.getFieldProps('ytUrl') }
                  />
                  <TextField
                    fullWidth
                    type="text"
                    label="Telegram url"
                    { ...themeForm.getFieldProps('tgUrl') }
                  />
                </FieldsContainer>
              </Stack>
              <Stack spacing={ 2 }>
                <Typography variant="subtitle1">
                  New Products
                </Typography>
                <FieldsContainer>
                  <Select
                    defaultValue={ newProducts }
                    value={ newProducts }
                    components={{ Option: SelectOption }}
                    options={ allProducts }
                    placeholder="Search..."
                    controlShouldRenderValue={ false }
                    onChange={ setNewProducts }
                    closeMenuOnSelect={ false }
                    hideSelectedOptions={ false }
                    noOptionsMessage={ () => 'No products found' }
                    isMulti
                  />
                </FieldsContainer>
                <ProductsContainer>
                  {
                    newProducts.map(product => (
                      <ShopProductCard
                        key={ product.id }
                        product={ product }
                      />
                    ))
                  }
                </ProductsContainer>
              </Stack>
              <Stack spacing={ 2 }>
                <Typography variant="subtitle1">
                  Recommended Products
                </Typography>
                <FieldsContainer>
                  <Select
                    defaultValue={ recommendedProducts }
                    value={ recommendedProducts }
                    components={{ Option: SelectOption }}
                    options={ allProducts }
                    placeholder="Search..."
                    controlShouldRenderValue={ false }
                    onChange={ setRecommendedProducts }
                    closeMenuOnSelect={ false }
                    hideSelectedOptions={ false }
                    noOptionsMessage={ () => 'No products found' }
                    isMulti
                  />
                </FieldsContainer>
                <ProductsContainer>
                  {
                    recommendedProducts.map(product => (
                      <ShopProductCard
                        key={ product.id }
                        product={ product }
                      />
                    ))
                  }
                </ProductsContainer>
              </Stack>
            </Stack>
            <Box sx={{ display: 'flex', mt: '32px' }}>
              <LoadingButton
                // disabled={ !isFormReady }
                color="secondary"
                size="large"
                type="submit"
                variant="contained"
                loading={ isSubmitting }
                onClick={ handleSubmit }
              >
                Save Settings
              </LoadingButton>
            </Box>
          </Form>
        </FormikProvider>
      </Container>
    </Page>
  );
};

export default ThemeConfig;

// Package modules
import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { LoadingButton } from '@mui/lab';
import { useNavigation } from 'react-navi';
import ReactQuill, { Quill } from "react-quill";
import htmlEditButton from "quill-html-edit-button";
import 'react-quill/dist/quill.snow.css';
import {
  Form,
  useFormik,
  FormikProvider
} from 'formik';
import {
  Box,
  Stack,
  Radio,
  styled,
  Container,
  TextField,
  Typography,
  RadioGroup,
  FormControlLabel
} from '@mui/material';
import Iconify from '../components/Iconify';

// Local modules
import Page from '../components/Page';
import FileLoader from '../components/FileLoader';
import { BLOG_TYPES } from '../lib/constants';
import { useBlogPosts, usePost } from '../lib/state';
import { convertFileToBase64 } from '../lib/utils';


Quill.register("modules/htmlEditButton", htmlEditButton);

// Constants
const EDITOR_MODULES = {
  toolbar: [
    [{ 'header': [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
    ['link', 'image', 'video']
  ],
  htmlEditButton: {
    msg: "Edit the content in HTML format", //Custom message to display in the editor, default: Edit HTML here, when you click "OK" the quill editor's contents will be replaced
    okText: "Ok", // Text to display in the OK button, default: Ok,
    cancelText: "Cancel", // Text to display in the cancel button, default: Cancel
    buttonHTML: "HTML", // Text to display in the toolbar button, default: <>
    buttonTitle: "Show HTML source", // Text to display as the tooltip for the toolbar button, default: Show HTML source
    syntax: false, // Show the HTML with syntax highlighting. Requires highlightjs on window.hljs (similar to Quill itself), default: false
    prependSelector: "div#myelement", // a string used to select where you want to insert the overlayContainer, default: null (appends to body),
    editorModules: {} // The default mod
  }
};

const EDITOR_FORMATS = [
  'header',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image', 'video'
];

// Styled components
const ReactQuillContainer = styled(Stack)`
  & .quill {
    background-color: ${({ theme }) => theme.palette.common.white};
    border-radius: 8px;
  }
  
  & .ql-toolbar.ql-snow {
    border-color: ${ ({ theme }) => theme.palette.grey['50032'] };
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  
  & .ql-container.ql-snow {
    border-color: ${ ({ theme }) => theme.palette.grey['50032'] };
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  
  & .ql-editor {
    font-size: 1rem;
    min-height: 200px;
  }
`;

// Exports
function BlogPostEditor({ blogType, postId }) {
  // const [post, setPost] = useState(null);
  const [postType, setPostType] = useState(blogType || BLOG_TYPES.NEWS);
  const [isFormReady, setIsFormReady] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const formRef = useRef(null);
  const editorRef = useRef(null);
  const fileLoaderRef = useRef(null)

  const { createPost } = useBlogPosts(blogType);
  const {
    data: post,
    deletePost,
    updatePost
  } = usePost(blogType, postId);

  console.log("👉 post", post, "👈")
  const [postImage, setPostImage] = useState(post?.cover ?? '');
  const [postContent, setPostContent] = useState(post?.body || '');

  const { navigate } = useNavigation();

  const PostSchema = Yup.object().shape({
    title: Yup.string().required('Title required')
  });

  const postForm = useFormik({
    initialValues: {
      title: post?.title ?? ''
    },
    validationSchema: PostSchema,
    onSubmit: async ({ title }) => {
      setIsSubmitting(true);
      const payload = {
        title,
        is_news: postType === BLOG_TYPES.NEWS,
        cover: postImage,
        body: postContent
      };

      try {
        if (post) {
          await updatePost(payload);
        } else {
          const createdPost = await createPost(payload);
          console.log("👉 createdPost", createdPost, "👈")
        }
      } catch (e) {
        console.error("👉", 'error', e, "👈")
      }

      setIsSubmitting(false);
      // Navigate to the created post page.
      await navigate(`/dashboard/blog?blogType=${blogType}`);
    }
  });

  const { errors, touched, values, handleSubmit, getFieldProps } = postForm;

  const handleDeletePost = async () => {
    setIsDeleting(true);
    await deletePost();
    setIsDeleting(false);
    await navigate(`/dashboard/blog?blogType=${blogType}`);
  };

  const handlePostImageChange = async (images) => {
    if (images[0]?.file) {
      console.log("👉 images[0]?.file", images[0]?.file, "👈")
      convertFileToBase64(images[0].file, setPostImage);
    }
  };

  useEffect(() => {
    if (values.title.trim().length === 0 || postContent.trim().length === 0) {
      setIsFormReady(false);
    } else {
      setIsFormReady(true);
    }
  }, [values, postContent]);

  useEffect(() => {
    if (fileLoaderRef.current !== null) {
      if (post?.cover || postImage.length > 0) {
        console.log("👉 postImage", postImage, "👈")
        fileLoaderRef.current.addFile(post?.cover ?? postImage);
      }
    }
  }, [fileLoaderRef, post, postImage]);

  return (
    <Page title="Dashboard: Blog Post">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={ 5 }
        >
          <Typography variant="h4" component="div">
            { postId ? 'Edit Post' : 'Create Post' }
            {
              postId && (
                <Typography variant="subtitle2" component="div">
                  postId: [{ postId }]
                </Typography>
              )
            }
          </Typography>
          {
            postId && (
              <LoadingButton
                startIcon={
                  <Iconify
                    icon="mdi:delete-outline"
                    width={ 22 }
                    height={ 22 }
                  />
                }
                color="error"
                size="large"
                type="submit"
                variant="contained"
                onClick={ handleDeletePost }
                loading={ isDeleting }
              >
                Delete Post
              </LoadingButton>
            )
          }
        </Stack>
        <FormikProvider value={ postForm }>
          <Form
            ref={ formRef }
            autoComplete="off"
            onSubmit={ handleSubmit }
            noValidate
          >
            <Stack spacing={ 4 }>
              <TextField
                fullWidth
                autoComplete="title"
                type="text"
                label="Post Title"
                { ...getFieldProps('title') }
                error={ Boolean(touched.title && errors.title) }
                helperText={ touched.title && errors.title }
              />
              {
                !postId && (
                  <Stack>
                    <Typography
                      variant="subtitle1"
                      component="p"
                    >
                      Post type
                    </Typography>
                    <RadioGroup
                      row
                      name="post-type"
                      defaultValue={ postType }
                      value={ postType }
                      onChange={ (event) => setPostType(event.target.value) }
                    >
                      <FormControlLabel
                        value={ BLOG_TYPES.NEWS }
                        control={ <Radio/> }
                        label="News"
                      />
                      <FormControlLabel
                        value={ BLOG_TYPES.ARTICLES }
                        control={ <Radio/> }
                        label="Article"
                      />
                    </RadioGroup>
                  </Stack>
                )
              }
              <Stack spacing={ 2 }>
                <Typography
                  variant="subtitle1"
                  component="p"
                >
                  Post Image
                </Typography>
                <FileLoader
                  ref={ fileLoaderRef }
                  maxFiles={ 1 }
                  files={ postImage.length > 0 ? [postImage] : [] }
                  setFiles={ handlePostImageChange }
                  label="Drag & Drop post image or <span class='filepond--label-action'>Browse</span>"
                />
              </Stack>
              <ReactQuillContainer spacing={ 2 }>
                <Typography
                  variant="subtitle1"
                  component="p"
                >
                  Post content
                </Typography>
                <ReactQuill
                  ref={ editorRef }
                  value={ postContent }
                  onChange={ setPostContent }
                  modules={ EDITOR_MODULES }
                  formats={ EDITOR_FORMATS }
                />
              </ReactQuillContainer>
              <Box sx={ { display: 'flex', justifyContent: 'flex-end' } }>
                <LoadingButton
                  disabled={ !isFormReady }
                  startIcon={
                    !postId ? <Iconify
                      icon="ic:baseline-add"
                      width={ 22 }
                      height={ 22 }
                    /> : null
                  }
                  color="secondary"
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={ isSubmitting }
                >
                  { postId ? 'Save Post' : 'Create Post' }
                </LoadingButton>
              </Box>
            </Stack>
          </Form>
        </FormikProvider>
      </Container>
    </Page>
  );
};

BlogPostEditor.propTypes = {
  postId: PropTypes.string
}

export default BlogPostEditor;

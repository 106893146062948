export default function DataGrid(theme) {
  return {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.common.white,
        }
      }
    }
  }
};

// Package modules
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';

// Local modules
import ShopProductCard from './ProductCard';

export default function ProductList({ products, productType, ...other }) {
  return (
    <Grid
      container
      spacing={ 3 }
      { ...other }
    >
      {
        products.map((product) => (
          <Grid key={ product.id } item xs={ 12 } sm={ 6 } md={ 3 }>
            <ShopProductCard
              product={ product }
              productType={ productType }
            />
          </Grid>
        ))
      }
    </Grid>
  );
}

ProductList.propTypes = {
  products: PropTypes.array.isRequired,
  productType: PropTypes.string
};

import * as Yup from 'yup';
import { useState } from 'react';
import {
  Form,
  useFormik,
  FormikProvider,
} from 'formik';
import {
  Stack,
  TextField,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

// Local modules
import Iconify from '../../../components/Iconify';
import { useAuthService } from '../../../lib/state';

// Exports
export default function LoginForm() {
  const [showPassword, setShowPassword] = useState(false);

  const { login }  = useAuthService();

  const LoginSchema = Yup.object().shape({
    username: Yup.string().required('Username is required'),
    password: Yup.string().required('Password is required')
  });

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
      remember: true
    },
    validationSchema: LoginSchema,
    onSubmit: async ({ username, password }) => {
      await login(username, password);
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={ formik }>
      <Form
        autoComplete="off"
        onSubmit={ handleSubmit }
        noValidate
      >
        <Stack spacing={ 3 }>
          <TextField
            fullWidth
            autoComplete="username"
            type="text"
            label="Username"
            { ...getFieldProps('username') }
            error={ Boolean(touched.username && errors.username) }
            helperText={ touched.username && errors.username }
          />
          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={ handleShowPassword }
                    edge="end"
                  >
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={ Boolean(touched.password && errors.password) }
            helperText={ touched.password && errors.password }
          />
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={ isSubmitting }
          >
            Login
          </LoadingButton>
        </Stack>
        {/*<Stack*/}
        {/*  direction="row"*/}
        {/*  alignItems="center"*/}
        {/*  justifyContent="space-between"*/}
        {/*  sx={{ my: 2 }}*/}
        {/*>*/}
        {/*  <FormControlLabel*/}
        {/*    control={*/}
        {/*      <Checkbox*/}
        {/*        { ...getFieldProps('remember') }*/}
        {/*        checked={ values.remember }*/}
        {/*      />*/}
        {/*    }*/}
        {/*    label="Remember me"*/}
        {/*  />*/}
        {/*  <Link*/}
        {/*    component={ RouterLink }*/}
        {/*    variant="subtitle2"*/}
        {/*    to="#"*/}
        {/*    underline="hover"*/}
        {/*  >*/}
        {/*    Forgot password?*/}
        {/*  </Link>*/}
        {/*</Stack>*/}
        {/*<LoadingButton*/}
        {/*  fullWidth*/}
        {/*  size="large"*/}
        {/*  type="submit"*/}
        {/*  variant="contained"*/}
        {/*  loading={ isSubmitting }*/}
        {/*>*/}
        {/*  Login*/}
        {/*</LoadingButton>*/}
      </Form>
    </FormikProvider>
  );
}

// Package modules
import PropTypes from 'prop-types';
import { TextField, InputAdornment } from '@mui/material';

// Local modules
import Iconify from '../../../components/Iconify';

// Exports
export default function BlogPostsSearch({ onChange }) {
  return (
    <TextField
      onChange={ onChange }
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Iconify
              icon="eva:search-fill"
              sx={{
                ml: 1,
                width: 20,
                height: 20,
                color: 'text.disabled'
              }}
            />
          </InputAdornment>
        )
      }}
    />
  );
}
BlogPostsSearch.propTypes = {
  onChange: PropTypes.func
};

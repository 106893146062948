// Package modules
import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { alpha, Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// eslint-disable-next-line import/no-named-default
import { default as BaseSelect } from 'react-select';
import { getOptionWithHighlights } from '../lib/utils';

// Local modules
import Iconify from './Iconify';

// Styled components
const StyledSelect = styled(BaseSelect)`
  & .react-select__control {
    min-height: 56px;
    font-size: 16px;
    box-shadow: none;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.palette.common.white};

    border: 1px solid ${({ theme }) => theme.palette.grey['50032']};

    &:hover {
      border-color: ${({ theme }) => theme.palette.primary.darker};
    }
    
    &--menu-is-open {
      border-width: 2px;
      border-color: ${({ theme }) => theme.palette.primary.darker};
      
      &:hover {
        border-color: ${({ theme }) => theme.palette.primary.darker};
      }
    }
  }
  
  & .react-select__value-container {
    padding-left: 14px;
  }
  
  & .react-select__single-value {
    font-size: 16px;
    font-weight: normal;
  }
  
  & .react-select__menu {
    color: ${({ theme }) => theme.palette.primary.dark};
    font-size: 16px;
    font-weight: normal;
    overflow: hidden;
    box-shadow: unset;
    border-radius: 8px;
    border: 2px solid ${({ theme }) => theme.palette.primary.darker};
  }
  
  & .react-select__option {
    padding-left: 14px;
    
    &.react-select__option--is-focused,
    &.react-select__option--is-selected,
    &:hover {
      background-color: ${({ theme }) => alpha(theme.palette.primary.darker, 0.11)};
      color: currentColor;
    }
  }
  
  & .react-select__multi-value {
    border-radius: 6px;
  }
  
  & .react-select__control--is-disabled {
    opacity: 0.6;
    background-color: ${({ theme }) => alpha(theme.palette.grey['500'], 0.11)};
  }
  
  .react-select__placeholder,
  .react-select__option--is-disabled {
    color: ${({ theme }) => theme.palette.grey['500']};
    font-weight: normal;
  }
`;

const IconWrapper = styled.div`
  padding-right: 10px;
  padding-top: 6px;
`;

const CustomSelectOption = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 6px 16px;
  
  ${({ isSelected, theme }) => isSelected && `
    background-color: ${alpha(theme.palette.grey['500'], 0.1)};
  `}
  
  &:hover {
    background-color: ${({ theme }) => alpha(theme.palette.primary.dark, 0.10)};
  }
`;

export const TextMark = styled(Typography)`
  & mark {
    background-color: ${({ theme }) => alpha(theme.palette.primary.dark, 0.15)};
  }
`;

const ArrowDown = () => {
  const theme = useTheme();

  return (
    <IconWrapper>
      <Iconify
        icon="ic:baseline-keyboard-arrow-down"
        width={ 20 }
        height={ 20 }
        color={ theme.palette.primary.dark }
      />
    </IconWrapper>
  );
};

export const SelectOption = ({
  data,
  innerProps,
  isSelected,
  selectProps,
}) => (
  <CustomSelectOption
    isSelected={ isSelected }
    { ...innerProps }
  >
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
      {
        data?.prepend ? data?.prepend : null
      }
      <TextMark
        variant="body1"
        dangerouslySetInnerHTML={{ __html: getOptionWithHighlights(selectProps.inputValue, data.label) }}
      />
    </Box>
    {
      isSelected ? (
        <Iconify icon="ic:baseline-minus" width={ 20 } height={ 20 } />
      ) : (
        <Iconify icon="ic:baseline-plus" width={ 20 } height={ 20 } />
      )
    }
  </CustomSelectOption>
);

// Exports
export const Select = ({
  components,
  classNamePrefix = 'react-select',
  ...remainingProps
}) => {
  return (
    <StyledSelect
      classNamePrefix={ classNamePrefix }
      maxMenuHeight={ 320 }
      components={{
        IndicatorsContainer: ArrowDown,
        ...components
      }}
      { ...remainingProps }
    />
  );
};

Select.propTypes = {
  components: PropTypes.object,
  classNamePrefix: PropTypes.string,
}


// Package modules
import React from 'react';
import {
  map,
  mount,
  route,
  compose,
  redirect,
  withView
} from 'navi'
import { BLOG_TYPES } from './lib/constants';

// Local modules
import Blog from './pages/Blog';
import Login from './pages/Login';
import ProductEditor from './pages/Product/ProductEditor';
import Products from './pages/Products';
import ThemeConfig from './pages/ThemeConfig';
import { AuthService } from './lib/authService';
import BlogPostEditor from './pages/BlogPostEditor';
import DashboardLayout from './layouts/dashboard';
import PartnerRequests from './pages/PartnerRequests';
import PartnerRequest from "./pages/PartnerRequest";
import MailLetters from './pages/MailLetters';
import MailLettersId from './pages/MailLettersId';
import Contacts from './pages/Contacts';
import ContactId from './pages/ContactId';

// const isLoggedIn = false;

// setTimeout(() => {
//   isLoggedIn = false;
// }, 60000);

export const appRoutes = mount({
  '*': map(async () => {
    const authService = AuthService.getAuthServiceInstance();
    const isLoggedIn = await authService.isLoggedIn();

    // console.log("authService > ", authService);
    // console.log("authenticatedUser > ", authenticatedUser);
    // console.log("isLoggedIn > ", isLoggedIn);

    // console.log("👉 request", request, "👈")
    // console.log("👉 authenticatedUser", authenticatedUser, "👈")
    // console.log("👉 isLoggedIn", isLoggedIn, "👈")


    return mount({
      '/': map(() => redirect('/login', { exact: false })),
      '/login': map(() => {
        if (isLoggedIn) {
          return redirect('/dashboard/theme', { exact: false });
        }

        return route({ view: <Login /> });
      }),
      '/dashboard': map(async () => {

        if (!isLoggedIn) {
          return redirect('/login', { exact: false });
        }

        return mount({
          '*': compose(
            withView(<DashboardLayout />),
            mount({
              '/theme': route({ view: <ThemeConfig/> }),
              '/partner-requests': route({ view: <PartnerRequests /> }),
                '/partner-requests/:partnerRequestId': route({
                    view:({route: {data}}) => (<PartnerRequest partnerRequestId={data.partnerRequestId} /> ),
                    getData: ({ params }) => ({ partnerRequestId: params.partnerRequestId })
                }),
                '/products': route({
                view: ({ route: { data }}) => (
                  <Products productType={ data.productType } />
                ),
                getData: ({ params }) => ({ productType: params.productType })
              }),
              '/products/add': route({
                view: ({ route: { data }}) => (
                  <ProductEditor productType={ data.productType } />
                ),
                getData: ({ params }) => ({ productType: params.productType })
              }),
              '/products/:productId': route({
                view: ({ route: { data } }) => (
                  <ProductEditor
                    productId={ data.productId }
                    productType={ data.productType }
                  />
                ),
                getData: ({ params }) => ({ productId: params.productId, productType: params.productType })
              }),
              // '/blog': route({ view: <Blog /> }),
              '/blog': map(({ query }) => {
                if (!query.blogType) {
                  return redirect(`/dashboard/blog?blogType=${BLOG_TYPES.NEWS}`, { exact: true });
                }

                return route({
                  view: ({ route: { data } }) => (
                    <Blog blogType={ data.blogType } />
                  ),
                  getData: ({ params }) => ({ blogType: params.blogType })
                })
              }),
              // '/blog/add': route({ view: <BlogPostEditor /> }),
              '/blog/add': route({
                view: ({ route: { data } }) => (
                  <BlogPostEditor
                    postId={ data.postId }
                    blogType={ data.blogType }
                  />
                ),
                getData: ({ params }) => ({ blogType: params.blogType })
              }),
              '/blog/edit/:postId': route({
                view: ({ route: { data } }) => (
                  <BlogPostEditor
                    postId={ data.postId }
                    blogType={ data.blogType }
                  />
                ),
                getData: ({ params }) => ({ postId: params.postId, blogType: params.blogType })
              }),
              '/mailletters': route({ view: <MailLetters /> }),
              '/mailletters/:maillettersId': route({
                view: ({ route: { data } }) => (
                  <MailLettersId maillettersId={data.maillettersId} />
                ),
                getData: ({ params }) => ({ maillettersId: params.maillettersId }),
              }),
              '/mailletters/add': route({
                view: ({ route: { data } }) => (
                  <MailLettersId maillettersId={data.maillettersId} />
                ),
                getData: ({ params }) => ({ maillettersType: params.maillettersType })
              }),
              '/contacts': route({
                view: <Contacts />
              }),
              '/contacts/:contactId': route({
                view: ({ route: { data } }) => (
                  <ContactId
                    contactId={ data.contactId }
                  />
                ),
                getData: ({ params }) => ({ contactId: params.contactId})

              }),
            })
          )
        });
      }),
    })
  })
});
